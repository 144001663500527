<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    
    <div id="page-content" v-if="!viLoader" >
      <!-- <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
      <button
                    type="button"
                    id="prevBtn"
                    class="backbtn"
                   @click="previousRoute"
                  >
                   <i class="fa fa-arrow-left"></i>
                  </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <div class="row mt-2 countsection">
            <div class="col-lg-12">
              <div class="countsect p-2 ml-2 mr-2">
                <ul class="dispflex" >
                  <li>
                    <span :class="count.feePlanCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'">
                      <i style="font-size: small;" :class="count.feePlanCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'"  ></i>
                      </span>Create Fee Plan
                  </li>
                  <li>
                   
                    <span :class="count.assignFeeTypesCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="count.assignFeeTypesCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span>Assign Fee Types
                  </li>
                   <li>
                   <span :class="count.feePlanApprovedCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="count.feePlanApprovedCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span>Assign Discount & Penalties
                  </li>
                  <li>
                    <span :class="count.feePlanApprovedCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="count.feePlanApprovedCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span>Approved Fee Plan
                  </li>
                   <li>
                    <span :class="count.feePlanApprovedCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="count.feePlanApprovedCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span>Fee Summary
                  </li>
                  <li>
                    <span :class="count.assignFeeToStudentsCount > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="count.assignFeeToStudentsCount > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span>Assign Fee to Students
                  </li>
                </ul>
              </div>
            </div>
          </div>
      <div class="row mt-2"> 
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 12px"
                  ><i class="fas fa-receipt"></i
                ></span>
                <h1>View Fee Plans</h1>
              </div>
               <div style="margin-top:10px"> <input type="text" v-model="searchWords" placeholder="Search Fee plans" /> </div>
             
              <div class="" style="width: 20%">
                <span class="has-float-label">
                   <select
                      @change="getSelectAcademicYear"
											class="form-control form-input"
											id="deptlistid"
											v-model="selectAcademicYear"
											>
											<option :value=null>--Select--</option>
											<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
												{{ item.from}} - {{ item.to }}
											</option>
											</select>
											<label for="acyearid">Academic Year <span class="required">*</span></label>
                </span>
              </div>
               <div class="mt-1">
                
                <!-- v-b-modal.crtfeeplan -->
                <button class="btn gry_bg tooltipt mpopup twoicn" @click="openFreePlanCreationScreen">
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Plan</span>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
            
                <div class="crtfeeplancont" style="padding: 0 13px">
                  <div
                    class="widjethdr dispflex"
                    style="align-items: baseline; padding: 10px 0"
                  >
                    <div style="width: auto">
                      <h1 style="margin: 0">Fee Plan</h1>
                    </div>
                    <!-- <div><input type="text" v-model="searchWords" placeholder="Search Fee plans"/></div>
                    <div class="mr-1">
                      
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.crtfeeplan
                      >
                        <i class="fas fa-file-invoice user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Plan</span>
                      </button>
                    </div> -->
                  </div>
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Plan Name</div>
                      <div class="cell">Status</div>
                      <div class="cell">Plan Start Date</div>
                      <div class="cell">Plan End Date</div>
                      <!-- <div class="cell">Activation Date</div> -->
                      <div class="cell">Fee Amount</div>
                      <div class="cell">Class</div>
                       <div class="cell">Last Modified By</div>
                      <div class="cell">Last Modified Date</div>
                      <div class="cell">Action</div>
                    </div>
                    <!-- <div v-if="feesPlanList.length > 0"> -->
                    <div class="resrow" 
                      v-for="(plan, index) in feesPlanList"
                      :key="index"
                    >
                     
                      <div  v-if="plan.type == 'Term-Fees'" data-title="Plan Name" class="cell">
                         <a href="javascript:void(0);" class="txtellipsis" :title="plan.planName"  @click.prevent="editPlan(index,'view')">{{ plan.planName }} 
                         </a>
                       </div>

                      <div  v-if="plan.status == 'Draft'" class="cell" style="color: brown;" data-title="Status">{{ plan.status }}</div>
					             <div  v-else class="cell"  data-title="Status">{{ plan.status }}</div>

                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Plan Start Date">
                        {{ plan.feesStartDate | dateFormat }}
                      </div>
                      <div v-else class="cell" data-title="Plan End Date">
                        {{ plan.billingDate | dateFormat }}
                      </div>

                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date">
                        {{ plan.feesEndDate | dateFormat }}
                      </div>
                       <div v-else class="cell" data-title="Fee Amount">
                        {{ plan.billingDate | dateFormat }}
                      </div>

                       <!-- <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Class">
                        {{ plan.feesDueDate | dateFormat }}
                      </div>
                      <div v-else class="cell" data-title="feesDueDate">
                        {{ plan.dueDate | dateFormat }}
                      </div> -->
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Active">$ {{ (plan.assignFee && plan.assignFee.totalAmount > 0) ? plan.assignFee.totalAmount : 0 }}</div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Active">
                                                 <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="viewClass(plan.classes)">View</a>
                      </div>
                      <div v-else class="cell" data-title="Active">$ {{ plan.adhocFeeLineItem.length > 0 ? gettotalAdhocFees(plan.adhocFeeLineItem) : 0 }}</div>
                       <div class="cell" data-title="Department Name">
                        <div class="txtellipsis" :title="plan.updatedBy.firstName +  plan.updatedBy.lastName">{{ plan.updatedBy ? plan.updatedBy.firstName + " " +  plan.updatedBy.lastName : '' }}</div>
                      </div>
                      <div  class="cell" data-title="Role">
                            {{ plan.updatedDate | dateFormat }}
                          </div>

                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Action">
                        <div class="dispflex" style="justify-content:stretch">
                         
                          <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                            <b-dropdown-item v-if="plan.reportingTo && plan.reportingTo == getUserId && plan.status == 'In Review'" @click.prevent="updateAction(plan._id,'Approved')">Approve</b-dropdown-item>
                            <b-dropdown-item v-if="plan.reportingTo && plan.reportingTo == getUserId && plan.status == 'In Review'" @click.prevent="updateAction(plan._id,'Draft')">Reject</b-dropdown-item>
                            <!-- <b-dropdown-item :disabled="(plan.status == 'In Review' && role.roleCode == 'SA') || plan.status == 'Active'" @click.prevent="redirectRoute(`/feesManagement/feesType/${plan._id}`)">Assign Fee Type</b-dropdown-item> -->
                            <!-- <b-dropdown-item  @click.prevent="redirectRoute(`/feesManagement/overallDiscountAndPenalities/${plan._id}`)">Add Discount & Penalities</b-dropdown-item> -->
                            <b-dropdown-item :disabled="plan.status == 'In Review' || plan.status == 'Draft' || plan.status == 'Active'" @click.prevent="redirectRoute(`/feesManagement/feesSummary/${plan._id}`)">Assign/Revoke Fees</b-dropdown-item>
                            <b-dropdown-item v-if="role.roleCode == 'SA' && plan.status == 'Draft'"  @click.prevent="submitForApproval(index)">Submit for Approval</b-dropdown-item>
                            
                          </b-dropdown>
                       
                        <button
                         :disabled="plan.status == 'In Review' || plan.status == 'Active' || ( plan.assignFeePlanStudent && plan.assignFeePlanStudent.length > 0)"
                          @click.prevent="editPlan(index,'edit')"
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit"></i
                          ><span data-title="Edit" class="tooltiptext" :style="plan.assignFeePlanStudent && plan.assignFeePlanStudent.length > 0 ? 'display: inline-block;background-color: black;' : ''"  >{{ plan.assignFeePlanStudent && plan.assignFeePlanStudent.length > 0 ? 'Revoke Fee Plan' : 'Edit' }}</span>
                        </button>
                        <button
                         :disabled="plan.status == 'In Review' || plan.status == 'Active'"
                          @click.prevent="viewEditPlan(index,'edit')"
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-eye"></i
                          ><span data-title="Edit" class="tooltiptext">View</span>
                        </button>
                        

                        <button :disabled="plan.status == 'In Review' || plan.status == 'Approved' || plan.status == 'Active'" class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
                        </div>

                      </div>
                      <div v-else class="cell" data-title="Action">
                       
                        <button
                          @click.prevent="
                            redirectRoute(`/feesManagement/plan/adhocfee/${plan._id}`)
                          "
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit"></i
                          ><span class="tooltiptext">Edit</span>
                        </button>
                         <button
                          @click.prevent="
                            redirectRoute(`/feesManagement/assignAdhocFess/${plan._id}`)
                          "
                          class="btn round blue tooltipt twoicn"
                        >
                         <i class="fas fa-user"></i>
                         
                          <span class="tooltiptext">Assign students</span>
                        </button>
                        <button class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
                        
                      </div>
                    
                    </div>
                    <!-- </div> -->
                    <!-- <div v-else class="resrow">
                      No data found
                    </div> -->
                  </div>
                </div>
                 <div class="text-center mt-4" v-if="feesPlanList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
               <!-- <div class="widjetfooter text-right dk_iconsml mt-3">
                  <button
                    type="button"
                    id="prevBtn"
                    class="btn btnsml"
                   @click="previousRoute"
                  >
                    <i class="fa fa-arrow-left"></i> Back
                  </button>
                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
     <b-modal
      id="crtfeeplan"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>{{ editId ? 'Update Fee Plan' : 'Create Fee Plan' }}</h2>
        <br />
        <div>
          <form
            data-vv-scope="crtfeeplanValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
            style="height: 310px;
    overflow-x: scroll;"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    @input="checkFeePlanName"
                    name="fpname"
                    v-model="feePlan.planName"
                    class="form-control form-input"
                    id="fpnameid"
                    placeholder="Fee Plan Name"
                  />
                  <label
                    v-if="errors.first('crtfeeplanValidate.fpname') || !isFeePlanNameAvailable"
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('crtfeeplanValidate.fpname') ? 'Fees Plan Name is required ' : 'Fees Plan Name already exist. please use a different one.' }} </label
                  >

                  <label v-else for="fpnameid"
                    >Fee Plan Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                   :disabled="feetypeAmountLength > 0 || editId"
                    @change="setPlanDate(feePlan.academicYear,'edit')"
                    v-model="feePlan.academicYear"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="acyearid"
                    name="acdyr"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('crtfeeplanValidate.acdyr')"
                    class="vi-error"
                    style="top: -1em"
                    >Academic year is required</label
                  >
                  <label v-else for="acyearid"
                    >Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    @input="setPlanEndDate(feePlan.feesStartDate)"
                    type="date"
                    :disabled="!feePlan.academicYear || feetypeAmountLength > 0"
                    :min="minPlanDate"
                    :max="maxPlanDate"
                    onkeydown="return false" 
                    name="fpstartdate"
                    v-model="feePlan.feesStartDate"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="Fee Plan State Date"
                  />

                  <label
                    v-if="errors.first('crtfeeplanValidate.fpstartdate')"
                    class="vi-error"
                    style="top: -1em"
                    >Fee Plan Start Date is required</label
                  >
                  <label v-else for="fpstartdateid"
                    >Fee Plan Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    @input="checkEndDateValidate(feePlan.feesStartDate,feePlan.feesEndDate)"
                    v-validate="'required'"
                    :disabled="!feePlan.feesStartDate || feetypeAmountLength > 0"
                    type="date"
                    :min="minFromPlanStartDate"
                    :max="maxPlanDate"
                    onkeydown="return false" 
                    name="addeadline"
                    v-model="feePlan.feesEndDate"
                    class="form-control form-input"
                    id="fpenddateid"
                    placeholder="Fee Plan End Date"
                  />
                  <label
                    v-if="errors.first('crtfeeplanValidate.addeadline')"
                    class="vi-error"
                    style="top: -1em"
                    >Fee Plan End Date is required</label
                  >
                  <label
                        v-else-if="minMonth"
                        class="vi-error"
                        for="pwdid"
                        style="top:-1em"
                        >Please Select Minimum 6 months in from plan start date</label>
                  <label v-else for="fpenddateid"
                    >Fee Plan End Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    name="duedate"
                    :min="feePlan.feesStartDate"
                    :max="feePlan.feesEndDate"
                    :disabled="!feePlan.feesEndDate || feetypeAmountLength > 0"
                    onkeydown="return false" 
                    v-model="feePlan.feesDueDate"
                    class="form-control form-input"
                    id="duedateid"
                    placeholder="Fee Plan End Date"
                  />

                  <label
                    v-if="errors.first('crtfeeplanValidate.duedate')"
                    class="vi-error"
                    style="top: -1em"
                    >Fee Plan Activation Date is required</label
                  >
                  <label v-else for="duedateid"
                    >Fee Plan Activation Date <span class="required">*</span></label
                  >
                </span>
              </div> -->
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                      class="form-control form-input"
                      name="PlanType"
                      v-validate="'required'"
                      v-model="feePlan.type"
                      >
                      <option :value=null>--Select--</option>
                      <option v-for="(item,index) in types" :key="index" :value="item.value">
                        {{ item.label }} 
                      </option>
                      </select>
                        <label
                          v-if="errors.first('crtfeeplanValidate.PlanType')"
                          class="vi-error"
                          style="top:-1em"
                          >Plan type is required</label
                        >
                        <label  v-else >Plan type <span class="required">*</span></label>
                </span>
              </div>
              <div v-if="editId" class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                   <v-select class="form-control form-input"
                      label="label"
                      name="AssignClasses"
                      v-validate="'required'"
                      multiple
                      
                      v-model="feePlan.classes"
                      :options="getformatClassList"
                      :reduce="label => label.value"
                    ></v-select>

                    <label
                    v-if="errors.first('crtfeeplanValidate.AssignClasses')"
                    class="vi-error"
                    style="top: -1em"
                    >Select minimun one class</label
                  >
                  <label v-else for="selclslistid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              <div v-else class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                   <v-select class="form-control form-input"
                      @input="getAllStudentsCount"
                      label="label"
                      name="AssignClasses"
                      v-validate="'required'"
                      multiple
                      v-model="feePlan.classes"
                      :options="getformatClassList"
                      :reduce="label => label.value"
                    ></v-select>
                    <label
                    v-if="errors.first('crtfeeplanValidate.AssignClasses')"
                    class="vi-error"
                    style="top: -1em"
                    >Select minimun one class</label
                  >
                  <label v-else for="selclslistid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    type="text"
                    disabled
                    
                    v-model="totalCountStudents"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <label  for="fpstartdateid"
                    >Total No. of Students</label
                  >
                </span>
              </div>

              <!-- <div
                      class="form-group input-group col-md-3 mt-3"
                      style="border-bottom: 1px solid #e5e5e5" 
                    >
                      <span style="color: #6c757d" class="mr-3">
                        Penality
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="showPenality"
                        />
                        <div class="slider"></div>
                      </label>
               </div>
             
               <div
                      class="form-group input-group col-md-3 mt-3"
                      style="border-bottom: 1px solid #e5e5e5" 
                    >
                      <span style="color: #6c757d" class="mr-3">
                        Discount
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="showDiscount"
                        />
                        <div class="slider"></div>
                      </label>
               </div>-->

              <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editId">
                <span class="has-float-label">
                  <input v-if="status == 'Draft'"
                    type="text"
                    disabled
                    style="color: brown;"
                    v-model="status"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <input v-else
                    type="text"
                    disabled
                    style="color: green;"
                    v-model="status"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />

                 
                  <label  for="fpstartdateid"
                    >Status </label
                  >
                </span>
              </div>
            </div>
             <div>
              <label
              style="margin: 0"
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Set Fee Terms & Fee Types
              </label>
              <div class="form-row">
          <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
            <span class="has-float-label">
              <select
              
                @change="checkFeetype"
                v-model="assignedFeeType.feeTypeId"
                :disabled="!isEdit && editId"
                v-validate="'required'"
                class="form-control form-input"
                id="deptlistid"
                name="feesType"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in feesTypeList" :key="index" :value="item._id">
                {{ item.name}}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeTypeValidate.feesType') || !isFeesTypeAvailable"
                class="vi-error"
                style="top:-1em"
                >{{ errors.first('crtfeeTypeValidate.feesType') ? 'Fees Type is required ' : 'Fees Type already exist. please select a different one.' }}  </label
              >

              <label v-else for="acyearid">Fees Type <span class="required">*</span></label>
              
              
            </span>
          </div> -->
          <div class="form-group input-group col-md-6 mt-3 pr-md-3">
          <span class="has-float-label">
            <input
              @input="setPlanEndDate(feePlan.feesStartDate)"
              type="date"
              :disabled="!feePlan.academicYear || feetypeAmountLength > 0"
              :min="minPlanDate"
              :max="maxPlanDate"
              onkeydown="return false" 
              name="fpstartdate"
              v-model="feePlan.feesStartDate"
              v-validate="'required'"
              class="form-control form-input"
              id="fpstartdateid"
              placeholder="Fee Plan State Date"
            />

            <label
              v-if="errors.first('crtfeeplanValidate.fpstartdate')"
              class="vi-error"
              style="top: -1em"
              >Fee Plan Start Date is required</label
            >
            <label v-else for="fpstartdateid"
              >Fee Plan Start Date <span class="required">*</span></label
            >
          </span>
        </div>
        <div class="form-group input-group col-md-6 mt-3">
          <span class="has-float-label">
            <input
              @input="checkEndDateValidate(feePlan.feesStartDate,feePlan.feesEndDate)"
              v-validate="'required'"
              :disabled="!feePlan.feesStartDate || feetypeAmountLength > 0"
              type="date"
              :min="minFromPlanStartDate"
              :max="maxPlanDate"
              onkeydown="return false" 
              name="addeadline"
              v-model="feePlan.feesEndDate"
              class="form-control form-input"
              id="fpenddateid"
              placeholder="Fee Plan End Date"
            />
            <label
              v-if="errors.first('crtfeeplanValidate.addeadline')"
              class="vi-error"
              style="top: -1em"
              >Fee Plan End Date is required</label
            >
            <label
                  v-else-if="minMonth"
                  class="vi-error"
                  for="pwdid"
                  style="top:-1em"
                  >Please Select Minimum 6 months in from plan start date</label>
            <label v-else for="fpenddateid"
              >Fee Plan End Date <span class="required">*</span></label
            >
          </span> 
        </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3">
            <span class="has-float-label">
              <select
                :disabled="!feePlan.feesEndDate"
                @change="setTerms"
                name="Billing Frequency"
                v-model="assignedFeeType.billingFrequency"
                class="form-control form-input"
                id="acyearid"
                placeholder="Billing Frequency"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in billingFrequencyList" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Billing Frequency')"
                class="vi-error"
                style="top:-1em"
                >Billing Frequency is required</label
              >
              <label v-else for="acyearid">Billing Frequency <span class="required">*</span></label>

            
            </span>
          </div>
          <!-- <div class="form-group input-group col-md-4 mt-3">
            <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
              <label
                style="color: #6c757d; margin-bottom: 0.2rem"
                class="mr-3"
                >Refundable</label
              >
              <label class="switch" style="margin-bottom: 0rem">
                <input  type="checkbox" id="refundable"  v-model="assignedFeeType.isRefundable" />
                <div class="slider"></div>
              </label>
            </div>
          </div>  -->
          
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            
            <span class="has-float-label">
              <select
                v-model="assignedFeeType.feeTermEvery"
                @change="setCustomTerms"
                name="terms every"
                class="form-control form-input"
                id="every id"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in termsEvery" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>

              <label
                v-if="errors.first('crtfeeplanValidate.terms every')"
                class="vi-error"
                style="top:-1em"
                >Fee Term every is required</label
              >

              <label v-else for="acyearid">Fee Term every <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              
              <select
                :disabled="!assignedFeeType.feeTermEvery"
                @change="clearInvoiceDate"
                v-model="assignedFeeType.feeInvoiceDate"
                name="Invoice dates"
                class="form-control form-input"
                id="Invoice dates id"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in invoiceDates" :key="index" :value="item">
                {{ item }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Invoice dates')"
                class="vi-error"
                style="top:-1em"
                >Set Invoice Date for every Term Month is required</label
              >

              <label v-else for="acyearid">Set Invoice Date for every Term Month <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              
              <select
                :disabled="!assignedFeeType.feeInvoiceDate"
                @change="setDueDates"
                v-model="assignedFeeType.feeDueDate"
                name="Due on"
                class="form-control form-input"
                id="dueonid"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in invoiceDueDates" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Due on')"
                class="vi-error"
                style="top:-1em"
                >Due on( Days from Invoice Date ) is required</label
              >
              <label v-else for="acyearid">Due on( Days from Invoice Date ) <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              <input
                  type="text"
                  disabled
                  name="startDate"
                  :value="noOfMonths"
                  class="form-control form-input"
                  id="stdDob"
                  placeholder="Plan Start Date"
              />
              
              <label  for="acyearid">Duration</label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="termList.length > 0">
            <span class="has-float-label">
              <input
                  type="text"
                  disabled
                  name="startDate"
                  :value="termList.length"
                  class="form-control form-input"
                  id="stdDob"
                  placeholder="Plan Start Date"
              />
              
              <label  for="acyearid">No of Terms <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="col-md-12 mt-3" id="monthlyterms" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
            <div id="prdwrapper" style="width: 100%">
              <div id="prdcontainer">
                <h1 class="termshd mb-3">{{ assignedFeeType.billingFrequency }} Term Fees</h1>
                <div id="content" class="mt-3">
                
                  <div class="terms" :key="index" v-for="(item,index) in termList">
                    <h1 class="termstitle">{{ item.term }}</h1>
                    <form data-vv-scope="crtfeeTypeValidate" id="crtfeeType" accept-charset="utf-8">
                    <div class="form-row">
                      <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                        <span class="has-float-label">
                          <input
                            type="date"
                            :min="item.minStartDate"
                            :max="item.maxStartDate"
                            @change="clearEndDate(item.feesStartDate,index)"
                            onkeydown="return false" 
                            :name="index + 'invoice'"
                            v-model="item.feesStartDate"
                            class="form-control form-input"
                            id="t1invdateid"
                            v-validate="'required'"
                            placeholder="Fee Start Date"
                          />
                          <label for="t1invdateid"
                            >Invoice Date
                            </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                        <span class="has-float-label">
                          <input
                            type="date"
                            onkeydown="return false" 
                            :name="index + 'due'"
                            :disabled="!item.feesStartDate"
                            :min="item.feesStartDate"
                            :max="item.maxStartDate"
                            v-model="item.feesEndDate"
                            class="form-control form-input"
                            id="t1invddateid"
                            v-validate="'required'"
                            placeholder="Fee Due Date"
                          />
                          <label for="t1invddateid"
                            >Invoice Due Date
                            </label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-12 mt-2">
                        <span class="has-float-label">
                          <input
                            type="number"
                            :name="index + 'amount'"
                            v-model="item.amount"
                            disabled
                            placeholder="Auto-filled"
                            class="form-control form-input"
                            id="t1amtid"
                          />
                          <label for="t1amtid">Amount (Auto-filled)
</label> 
                        </span>
                      </div>
                      <span class="required" v-if="errors.first(`crtfeeTypeValidate.${index + 'invoice'}`) || errors.first(`crtfeeTypeValidate.${index + 'due'}`)"> Invoice Date ,Invoice Due Date is required</span>
                    </div>
                    </form>
                  </div>
                </div>
              </div>
                <button id="slideLeft" @click.prevent="moveLeftSide"><i class="fas fa-angle-left"></i></button>
                <button id="slideRight" @click.prevent="moveRightSide"><i class="fas fa-angle-right"></i></button>
            </div>
          </div>
          <div class="col-md-12 mt-3 pt-3" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
          <div class="text-right dk_iconsml" >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn" style="width:159px"
              
              @click.prevent="addFeeTypes"
            >
                <span>Add Fee Type</span>
            </button>
          </div>
          </div>
              </div>
              <div class="row" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
                    <div class="col-lg-12" style="margin-bottom: 2%;">
                       
                          <div class="admintbl mt-1">
                            <table border="1">
                              <thead>
                                <tr>
                                  <th scope="col" rowspan="2">
                                    Fees Type <span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Billing Frequency<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Refundable<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Apply To<span class="required"
                                      >*</span
                                    >
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Discount<span class="required">*</span>
                                  </th>
                                   <th scope="col" rowspan="2">
                                    Amount<span class="required"
                                      >*</span
                                    >
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, trindex) in assignedFeeType.oneTimeFrequency" :key="trindex">
                                  <td data-label="Fee Name">
                                    <select
                                    @change="checkAlreadySelected(item.feeTypeId,trindex)"
                                    v-model="item.feeTypeId"
                                    v-validate="'required'"
                                    class="form-control form-input"
                                    id="deptlistid"
                                     :name="trindex + 'feeType'" 
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(data,index) in feesTypeList" :key="index" :value="data._id">
                                    {{ data.name }}
                                  </option>
                                  </select>
                                  <span style="font-size: smaller;" class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeType'}`)"> Fee type is required</span>
                                  </td>
                                  <td data-label="prefix">
                                    <select
                                    @change="setfrequencyAndamount(item.billingFrequency,trindex)"
                                    v-model="item.billingFrequency"
                                    v-validate="'required'"
                                     :name="trindex + 'feeBilFreq'" 
                                    class="form-control form-input"
                                    id="deptlistid"
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(data,index) in oneTimebillingFrequencyList" :key="index" :value="data">
                                    {{ data }}
                                  </option>
                                  </select>
                                 <span style="font-size: smaller;" class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeBilFreq'}`)"> Billing frequency is required</span>

                                  </td>
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input v-model="item.isRefundable" class="form-control form-input" type="checkbox" id="isDiscountable"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                   <td data-label="Fee Name">
                                   <a href="javascript:void(0);"  @click.prevent="setApplyTerms(trindex)" >{{ (item.term && item.term.length > 0) ? 'View Terms' : 'Select Terms' }}</a>
                                    <span class="required" v-if="item.feeTypeId && item.billingFrequency && item.term.length == 0" style="font-size: smaller;"> Apply to is required</span>
                                  </td>
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input v-model="item.isDiscount" @change="checkValue(item.isDiscount)" class="form-control form-input" type="checkbox" id="refundable"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                  <td data-label="Fee Name">
                                    <input
                                      min="1" 
                                      v-validate="'required|numeric|min:1'"
                                      v-model="item.amount"
                                      @input="setTermsAmount"
                                      type="number"
                                      :name="trindex + 'feeAmount'" 
                                      class="form-control form-input"
                                    />
                                     <span class="required" style="font-size: smaller;" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeAmount'}`)"> Amount is required</span>
                                  </td>
                                  <td>
                                    <button class="btn round orange tooltipt"  @click.prevent="removeLineItem(trindex)">
                                     <i class="fas fa-minus-circle"  style="padding-top:4px;"></i><span class="tooltiptext">Remove</span>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                       
                    </div>
              </div>   
              </div>
               <div>
                  <div style="padding:10px;width:100%;background:#f1f1f1">
								<label  style="margin-bottom:0">Discount And Penality 
								
								</label>
								<!-- <label class="custradio" style="margin-bottom:0">ClassRoom
								  <input type="radio"  value="classRoom" name="schedule">
								  <span class="checkmark"></span>
								</label> -->
							</div>
              <label
              
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Discount 
              <span style="float:right">
             
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          :disabled="checkFeetypeDiscount"
                          v-model="showDiscount"
                        />
                        <div class="slider"></div>
                      </label>
                      </span>
              </label>
           
               <div class="form-row " v-if="showDiscount">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          @change="cleardiscount"
                          v-model="discount.mode"
                          name="Billingmode"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        
                        </select>
                         <label
                          v-if="errors.first('crtfeeplanValidate.Billingmode')"
                          class="vi-error"
                          style="top:-1em"
                          >Select Mode is required</label
                        >
                          <label v-else >Select Mode <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 mt-3">						
                        <span class="has-float-label" v-if="discount.mode == 'Rs($)'">
                        <input type="number" name="disamount"  v-validate="'required|numeric|min:0'" v-model="discount.discountAmountValue" class="form-control form-input">
                          <label
                          v-if="errors.first('crtfeeplanValidate.disamount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount amount is required</label
                        >
                        <label v-else for="pervalid">Discount Amount <span class="required">*</span></label>
                        </span>
                        <span class="has-float-label" v-else>
                        <input  name="penalityAmount"  v-validate="'required|min:1'" v-model="discount.discountPercentageValue" class="form-control form-input" id="pervalid" placeholder="Enter the Discount" >
                          <label
                          v-if="errors.first('crtfeeplanValidate.penalityAmount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount (%) is required</label
                        > 
                        <label v-else for="pervalid">Discount (%) <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input  v-model="discount.termPaid"  class="form-control form-input" id="trmpaidlistid" disabled placeholder="Term Paid" >
                         
                        </span>
                     </div>
                </div>
              </div>
              <div > 
                 
              <label
              
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Penality

               <span style="float:right">
             
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="showPenality"
                        />
                        <div class="slider"></div>
                      </label>
                      </span>
              </label>
               <div class="form-row" v-if="showPenality">
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          @change="clearPenality"
                          v-model="penality.mode"
                          name="BillingPenalityFrequency"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        </select>

                          <label
                          v-if="errors.first('crtfeeplanValidate.BillingPenalityFrequency')"
                          class="vi-error"
                          style="top:-1em"
                          > Select Mode is required</label
                        > 
                        <label v-else for="pervalid">Select Mode <span class="required">*</span></label>
                          <!-- <label for="sltmodeid">Select Mode <span class="required">*</span></label> -->
                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 mt-3">						
                        <span class="has-float-label" v-if="penality.mode == 'Rs($)'">
                        <input type="number" name="pervaamountl"  v-validate="'required|numeric|min:0'" v-model="penality.penaltyAmountValue" class="form-control form-input" id="pervalid"  >

                        <label
                          v-if="errors.first('crtfeeplanValidate.pervaamountl')"
                          class="vi-error"
                          style="top:-1em"
                          > Penality amount is required</label
                        > 
                        <label v-else for="pervalid">Enter the penality amount <span class="required">*</span></label>

                        </span>
                        <span class="has-float-label" v-else>
                        <input  min="1" name="perval" v-validate="'required|numeric|min:1'" v-model="penality.penaltyPercentageValue" class="form-control form-input" id="pervalid"  >

                        <label
                          v-if="errors.first('crtfeeplanValidate.perval')"
                          class="vi-error"
                          style="top:-1em"
                          > Penality percentage is required</label>

                        <label v-else for="pervalid">Enter the Penality percentage <span class="required">*</span></label>

                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <span class="has-float-label">
                        <input type="number" min="1" name="daysval" v-validate="'required|numeric|min:1|max:15'" v-model="penality.paidAfter" class="form-control form-input" id="daysvalid"  >
                        
                        <label
                          v-if="errors.first('crtfeeplanValidate.daysval')"
                          class="vi-error"
                          style="top:-1em"
                          > Paid after is required</label
                        > 
                        <label v-else for="pervalid">Paid after <span class="required">*</span></label>
                        </span>
                     </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <label for="daysvalid">Days from Invoice Due Date </label>
                     </div>
                    
                  </div>
              </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5;text-align: center;">
            <!-- <div class="dk_icon">
            </div> -->
            <div class="dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                v-if="editId"
                @click.prevent="updateFeePlan"
              >
                  <span>Update</span>
              </button>
               <button  v-else
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="createFeePlan"
              >
                  <span>Save</span>
              </button>
              
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
     </b-modal>
     <b-modal
      id="viewEditfeeplan"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closeViewEditPopUp">×</button>
    <!-- <button type="button" aria-label="Close" class="close"  @click.prevent="closeViewEditPopUp">×</button> -->
      <div class="contact-form">
        <div class="dispflex mt-3 pt-3">
            <div class="dk_icon">
              <h2>{{ editId ? 'View Fee Plan' : 'Create Fee Plan' }}</h2>
            </div>
            <div class="text-right dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                v-if="!isEdit"
                @click.prevent="isTrueEnable"
              >
                  <span>Edit</span>
              </button>
              
              
              <button
                @click.prevent="closeViewEditPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>

       
        <br />
        <div>
          <form
            data-vv-scope="crtfeeplanValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
            style="height: 340px;
    overflow-x: scroll;"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    :disabled="!isEdit"
                    @input="checkFeePlanName"
                    name="fpname"
                    v-model="feePlan.planName"
                    class="form-control form-input"
                    id="fpnameid"
                    placeholder="Fee Plan Name"
                  />
                  <label
                    v-if="errors.first('crtfeeplanValidate.fpname') || !isFeePlanNameAvailable"
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('crtfeeplanValidate.fpname') ? 'Fees Plan Name is required ' : 'Fees Plan Name already exist. please use a different one.' }} </label
                  >

                  <label v-else for="fpnameid"
                    >Fee Plan Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                   :disabled="feetypeAmountLength > 0 || editId"
                    @change="setPlanDate(feePlan.academicYear,'edit')"
                    v-model="feePlan.academicYear"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="acyearid"
                    name="acdyr"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('crtfeeplanValidate.acdyr')"
                    class="vi-error"
                    style="top: -1em"
                    >Academic year is required</label
                  >
                  <label v-else for="acyearid"
                    >Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
             
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                      class="form-control form-input"
                      name="PlanType"
                      :disabled="!isEdit"
                      v-validate="'required'"
                      v-model="feePlan.type"
                      >
                      <option :value=null>--Select--</option>
                      <option v-for="(item,index) in types" :key="index" :value="item.value">
                        {{ item.label }} 
                      </option>
                      </select>
                        <label
                          v-if="errors.first('crtfeeplanValidate.PlanType')"
                          class="vi-error"
                          style="top:-1em"
                          >Plan type is required</label
                        >
                        <label  v-else >Plan type <span class="required">*</span></label>
                </span>
              </div>
              <div v-if="editId" class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                   <v-select class="form-control form-input"
                      label="label"
                      name="AssignClasses"
                      v-validate="'required'"
                      multiple
                      :disabled="!isEdit"
                      
                      v-model="feePlan.classes"
                      :options="getformatClassList"
                      :reduce="label => label.value"
                    ></v-select>

                    <label
                    v-if="errors.first('crtfeeplanValidate.AssignClasses')"
                    class="vi-error"
                    style="top: -1em"
                    >Select minimun one class</label
                  >
                  <label v-else for="selclslistid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              <div v-else class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                   <v-select class="form-control form-input"
                      @input="getAllStudentsCount"
                      label="label"
                      name="AssignClasses"
                      :disabled="!isEdit"
                      v-validate="'required'"
                      multiple
                      v-model="feePlan.classes"
                      :options="getformatClassList"
                      :reduce="label => label.value"
                    ></v-select>
                    <label
                    v-if="errors.first('crtfeeplanValidate.AssignClasses')"
                    class="vi-error"
                    style="top: -1em"
                    >Select minimun one class</label
                  >
                  <label v-else for="selclslistid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    type="text"
                    disabled
                    
                    v-model="totalCountStudents"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <label  for="fpstartdateid"
                    >Total No. of Students</label
                  >
                </span>
              </div>

              <!-- <div
                      class="form-group input-group col-md-3 mt-3"
                      style="border-bottom: 1px solid #e5e5e5" 
                    >
                      <span style="color: #6c757d" class="mr-3">
                        Penality
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="showPenality"
                        />
                        <div class="slider"></div>
                      </label>
               </div>
             
               <div
                      class="form-group input-group col-md-3 mt-3"
                      style="border-bottom: 1px solid #e5e5e5" 
                    >
                      <span style="color: #6c757d" class="mr-3">
                        Discount
                        <span class="required">*</span></span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="showDiscount"
                        />
                        <div class="slider"></div>
                      </label>
               </div>-->

              <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="editId">
                <span class="has-float-label">
                  <input v-if="status == 'Draft'"
                    type="text"
                    disabled
                    style="color: brown;"
                    v-model="status"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <input v-else
                    type="text"
                    disabled
                    style="color: green;"
                    v-model="status"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />

                 
                  <label  for="fpstartdateid"
                    >Status </label
                  >
                </span>
              </div>
            </div>
             <div>
              <label
              style="margin: 0"
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Set Fee Terms & Fee Types
              </label>
              <div class="form-row">
       
          <div class="form-group input-group col-md-6 mt-3 pr-md-3">
          <span class="has-float-label">
            <input
              @input="setPlanEndDate(feePlan.feesStartDate)"
              type="date"
              :disabled="!feePlan.academicYear || feetypeAmountLength > 0 || !isEdit"
              :min="minPlanDate"
              :max="maxPlanDate"
              onkeydown="return false" 
              name="fpstartdate"
              v-model="feePlan.feesStartDate"
              v-validate="'required'"
              class="form-control form-input"
              id="fpstartdateid"
              placeholder="Fee Plan State Date"
            />

            <label
              v-if="errors.first('crtfeeplanValidate.fpstartdate')"
              class="vi-error"
              style="top: -1em"
              >Fee Plan Start Date is required</label
            >
            <label v-else for="fpstartdateid"
              >Fee Plan Start Date <span class="required">*</span></label
            >
          </span>
        </div>
        <div class="form-group input-group col-md-6 mt-3">
          <span class="has-float-label">
            <input
              @input="checkEndDateValidate(feePlan.feesStartDate,feePlan.feesEndDate)"
              v-validate="'required'"
              :disabled="!feePlan.feesStartDate || feetypeAmountLength > 0 || !isEdit"
              type="date"
              :min="minFromPlanStartDate"
              :max="maxPlanDate"
              onkeydown="return false" 
              name="addeadline"
              v-model="feePlan.feesEndDate"
              class="form-control form-input"
              id="fpenddateid"
              placeholder="Fee Plan End Date"
            />
            <label
              v-if="errors.first('crtfeeplanValidate.addeadline')"
              class="vi-error"
              style="top: -1em"
              >Fee Plan End Date is required</label
            >
            <label
                  v-else-if="minMonth"
                  class="vi-error"
                  for="pwdid"
                  style="top:-1em"
                  >Please Select Minimum 6 months in from plan start date</label>
            <label v-else for="fpenddateid"
              >Fee Plan End Date <span class="required">*</span></label
            >
          </span> 
        </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3">
            <span class="has-float-label">
              <select
                :disabled="!feePlan.feesEndDate || !isEdit"
                @change="setTerms"
                name="Billing Frequency"
                v-model="assignedFeeType.billingFrequency"
                class="form-control form-input"
                id="acyearid"
                placeholder="Billing Frequency"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in billingFrequencyList" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Billing Frequency')"
                class="vi-error"
                style="top:-1em"
                >Billing Frequency is required</label
              >
              <label v-else for="acyearid">Billing Frequency <span class="required">*</span></label>

            
            </span>
          </div>
        
          
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            
            <span class="has-float-label">
              <select
                v-model="assignedFeeType.feeTermEvery"
                @change="setCustomTerms"
                :disabled="!isEdit"
                name="terms every"
                class="form-control form-input"
                id="every id"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in termsEvery" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>

              <label
                v-if="errors.first('crtfeeplanValidate.terms every')"
                class="vi-error"
                style="top:-1em"
                >Fee Term every is required</label
              >

              <label v-else for="acyearid">Fee Term every <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              
              <select
                :disabled="!assignedFeeType.feeTermEvery  || !isEdit"
                @change="clearInvoiceDate"
                v-model="assignedFeeType.feeInvoiceDate"
                name="Invoice dates"
                class="form-control form-input"
                id="Invoice dates id"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in invoiceDates" :key="index" :value="item">
                {{ item }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Invoice dates')"
                class="vi-error"
                style="top:-1em"
                >Set Invoice Date for every Term Month is required</label
              >

              <label v-else for="acyearid">Set Invoice Date for every Term Month <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              
              <select
                :disabled="!assignedFeeType.feeInvoiceDate || !isEdit"
                @change="setDueDates"
                v-model="assignedFeeType.feeDueDate"
                name="Due on"
                class="form-control form-input"
                id="dueonid"
                v-validate="'required'"
              >
              <option :value=null >  -- Select --  </option>
              <option v-for="(item,index) in invoiceDueDates" :key="index" :value="item.value">
                {{ item.label }}
              </option>
              </select>
              <label
                v-if="errors.first('crtfeeplanValidate.Due on')"
                class="vi-error"
                style="top:-1em"
                >Due on( Days from Invoice Date ) is required</label
              >
              <label v-else for="acyearid">Due on( Days from Invoice Date ) <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
            <span class="has-float-label">
              <input
                  type="text"
                  disabled
                  name="startDate"
                  :value="noOfMonths"
                  class="form-control form-input"
                  id="stdDob"
                  placeholder="Plan Start Date"
              />
              
              <label  for="acyearid">Duration</label>
            
            </span>
          </div>
          <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="termList.length > 0">
            <span class="has-float-label">
              <input
                  type="text"
                  disabled
                  name="startDate"
                  :value="termList.length"
                  class="form-control form-input"
                  id="stdDob"
                  placeholder="Plan Start Date"
              />
              
              <label  for="acyearid">No of Terms <span class="required">*</span></label>
            
            </span>
          </div>
          <div class="col-md-12 mt-3" id="monthlyterms" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
            <div id="prdwrapper" style="width: 100%">
              <div id="prdcontainer">
                <h1 class="termshd mb-3">{{ assignedFeeType.billingFrequency }} Term Fees</h1>
                <div id="content" class="mt-3">
                
                  <div class="terms" :key="index" v-for="(item,index) in termList">
                    <h1 class="termstitle">{{ item.term }}</h1>
                    <form data-vv-scope="crtfeeTypeValidate" id="crtfeeType" accept-charset="utf-8">
                    <div class="form-row">
                      <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                        <span class="has-float-label">
                          <input
                            type="date"
                            :min="item.minStartDate"
                            :max="item.maxStartDate"
                            @change="clearEndDate(item.feesStartDate,index)"
                            onkeydown="return false" 
                            :name="index + 'invoice'"
                            :disabled="!isEdit"
                            v-model="item.feesStartDate"
                            class="form-control form-input"
                            id="t1invdateid"
                            v-validate="'required'"
                            placeholder="Fee Start Date"
                          />
                          <label for="t1invdateid"
                            >Invoice Date
                            </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                        <span class="has-float-label">
                          <input
                            type="date"
                            onkeydown="return false" 
                            :name="index + 'due'"
                            :disabled="!item.feesStartDate"
                            :min="item.feesStartDate"
                            :max="item.maxStartDate"
                            v-model="item.feesEndDate"
                            class="form-control form-input"
                            id="t1invddateid"
                            v-validate="'required'"
                            placeholder="Fee Due Date"
                          />
                          <label for="t1invddateid"
                            >Invoice Due Date
                            </label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-12 mt-2">
                        <span class="has-float-label">
                          <input
                            type="number"
                            :name="index + 'amount'"
                            v-model="item.amount"
                            disabled
                            placeholder="Auto-filled"
                            class="form-control form-input"
                            id="t1amtid"
                          />
                          <label for="t1amtid">Amount (Auto-filled)
</label> 
                        </span>
                      </div>
                      <span class="required" v-if="errors.first(`crtfeeTypeValidate.${index + 'invoice'}`) || errors.first(`crtfeeTypeValidate.${index + 'due'}`)"> Invoice Date ,Invoice Due Date is required</span>
                    </div>
                    </form>
                  </div>
                </div>
              </div>
                <button  :disabled="!isEdit" id="slideLeft" @click.prevent="moveLeftSide"><i class="fas fa-angle-left"></i></button>
                <button id="slideRight" @click.prevent="moveRightSide"><i class="fas fa-angle-right"></i></button>
            </div>
          </div>
          <div class="col-md-12 mt-3 pt-3" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
          <div class="text-right dk_iconsml" >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn" style="width:159px"
              :disabled="!isEdit"
              @click.prevent="addFeeTypes"
            >
                <span>Add Fee Type</span>
            </button>
          </div>
          </div>
              </div>
              <div class="row" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
                    <div class="col-lg-12" style="margin-bottom: 2%;">
                       
                          <div class="admintbl mt-1">
                            <table border="1">
                              <thead>
                                <tr>
                                  <th scope="col" rowspan="2">
                                    Fees Type <span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Billing Frequency<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Refundable<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Apply To<span class="required"
                                      >*</span
                                    >
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Discount<span class="required">*</span>
                                  </th>
                                   <th scope="col" rowspan="2">
                                    Amount<span class="required"
                                      >*</span
                                    >
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, trindex) in assignedFeeType.oneTimeFrequency" :key="trindex">
                                  <td data-label="Fee Name">
                                    <select
                                    @change="checkAlreadySelected(item.feeTypeId,trindex)"
                                    v-model="item.feeTypeId"
                                    :disabled="!isEdit"
                                    v-validate="'required'"
                                    class="form-control form-input"
                                    id="deptlistid"
                                     :name="trindex + 'feeType'" 
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(data,index) in feesTypeList" :key="index" :value="data._id">
                                    {{ data.name }}
                                  </option>
                                  </select>
                                  <span class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeType'}`)"> Fee type is required</span>
                                  </td>
                                  <td data-label="prefix">
                                    <select
                                    @change="setfrequencyAndamount(item.billingFrequency,trindex)"
                                    v-model="item.billingFrequency"
                                    v-validate="'required'"
                                    :disabled="!isEdit"
                                     :name="trindex + 'feeBilFreq'" 
                                    class="form-control form-input"
                                    id="deptlistid"
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(data,index) in oneTimebillingFrequencyList" :key="index" :value="data">
                                    {{ data }}
                                  </option>
                                  </select>
                                 <span class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeBilFreq'}`)"> Billing frequency is required</span>

                                  </td>
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input  :disabled="!isEdit" v-model="item.isRefundable" class="form-control form-input" type="checkbox" id="refundable"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input  :disabled="!isEdit" v-model="item.isDiscount" class="form-control form-input" type="checkbox" id="isDiscount"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                   <td data-label="Fee Name">
                                   <a href="javascript:void(0);"  :disabled="!isEdit" @click.prevent="setApplyTerms(trindex)" >{{ (item.term && item.term.length > 0) ? 'View Terms' : 'Select Terms' }}</a>
                                    <span class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'applyTo'}`)"> Apply to is required</span>
                                  </td>
                                  
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input  :disabled="!isEdit" v-model="item.isDiscount" class="form-control form-input" type="checkbox" id="isDiscount"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                  <td data-label="Fee Name">
                                    <input
                                      min="1" 
                                      v-validate="'required|numeric|min:1'"
                                      v-model="item.amount"
                                      @input="setTermsAmount"
                                      type="number"
                                      :disabled="!isEdit"
                                      :name="trindex + 'feeAmount'" 
                                      class="form-control form-input"
                                    />
                                     <span class="required" v-if="errors.first(`crtfeeplanValidate.${trindex + 'feeAmount'}`)"> Amount is required</span>
                                  </td>
                                  <td>
                                    <button class="btn round orange tooltipt"  @click.prevent="removeLineItem(trindex)">
                                     <i class="fas fa-minus-circle"  style="padding-top:4px;"></i><span class="tooltiptext">Remove</span>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                       
                    </div>
              </div>   
              </div>
               <div>
                  <div style="padding:10px;width:100%;background:#f1f1f1">
								<label  style="margin-bottom:0">Discount And Penality 
								
								</label>
								<!-- <label class="custradio" style="margin-bottom:0">ClassRoom
								  <input type="radio"  value="classRoom" name="schedule">
								  <span class="checkmark"></span>
								</label> -->
							</div>
              <label
              
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Discount
              <span style="float:right">
             
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          :disabled="!isEdit"
                          v-model="showDiscount"
                        />
                        <div class="slider"></div>
                      </label>
                      </span>
              </label>
           
               <div class="form-row " v-if="showDiscount">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          :disabled="!isEdit"
                          @change="cleardiscount"
                          v-model="discount.mode"
                          name="Billingmode"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        
                        </select>
                         <label
                          v-if="errors.first('crtfeeplanValidate.Billingmode')"
                          class="vi-error"
                          style="top:-1em"
                          >Select Mode is required</label
                        >
                          <label v-else >Select Mode <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 mt-3">						
                        <span class="has-float-label" v-if="discount.mode == 'Rs($)'">
                        <input type="number"  :disabled="!isEdit" name="disamount"  v-validate="'required|numeric|min:0'" v-model="discount.discountAmountValue" class="form-control form-input">
                          <label
                          v-if="errors.first('crtfeeplanValidate.disamount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount amount is required</label
                        >
                        <label v-else for="pervalid">Discount Amount <span class="required">*</span></label>
                        </span>
                        <span class="has-float-label" v-else>
                        <input   :disabled="!isEdit" name="penalityAmount"  v-validate="'required|min:1'" v-model="discount.discountPercentageValue" class="form-control form-input" id="pervalid" placeholder="Enter the Discount" >
                          <label
                          v-if="errors.first('crtfeeplanValidate.penalityAmount')"
                          class="vi-error"
                          style="top:-1em"
                          >Discount (%) is required</label
                        > 
                        <label v-else for="pervalid">Discount (%) <span class="required">*</span></label>
                        </span>
                     </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <input   :disabled="!isEdit" v-model="discount.termPaid"  class="form-control form-input" id="trmpaidlistid" disabled placeholder="Term Paid" >
                         
                        </span>
                     </div>
                </div>
              </div>
              <div > 
                 
              <label
              
              for="accordiongroup-2"
              class="blutitle accordion-label"
            >
              Penality

               <span style="float:right">
             
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          :disabled="!isEdit"
                          v-model="showPenality"
                        />
                        <div class="slider"></div>
                      </label>
                      </span>
              </label>
               <div class="form-row" v-if="showPenality">
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                          @change="clearPenality"
                          v-model="penality.mode"
                          :disabled="!isEdit"
                          name="BillingPenalityFrequency"
                          class="form-control form-input"
                          id="modeid"
                          placeholder="Billing Mode"
                          v-validate="'required'"
                        >
                        <option :value=null >  -- Select --  </option>
                        <option v-for="(item,index) in modeList" :key="index" :value="item">
                          {{ item }}
                        </option>
                        </select>

                          <label
                          v-if="errors.first('crtfeeplanValidate.BillingPenalityFrequency')"
                          class="vi-error"
                          style="top:-1em"
                          > Select Mode is required</label
                        > 
                        <label v-else for="pervalid">Select Mode <span class="required">*</span></label>
                          <!-- <label for="sltmodeid">Select Mode <span class="required">*</span></label> -->
                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 mt-3">						
                        <span class="has-float-label" v-if="penality.mode == 'Rs($)'">
                        <input  :disabled="!isEdit" type="number" name="pervaamountl"  v-validate="'required|numeric|min:0'" v-model="penality.penaltyAmountValue" class="form-control form-input" id="pervalid"  >

                        <label
                          v-if="errors.first('crtfeeplanValidate.pervaamountl')"
                          class="vi-error"
                          style="top:-1em"
                          > Penality amount is required</label
                        > 
                        <label v-else for="pervalid">Enter the penality amount <span class="required">*</span></label>

                        </span>
                        <span class="has-float-label" v-else>
                        <input  :disabled="!isEdit" min="1" name="perval" v-validate="'required|numeric|min:1'" v-model="penality.penaltyPercentageValue" class="form-control form-input" id="pervalid"  >

                        <label
                          v-if="errors.first('crtfeeplanValidate.perval')"
                          class="vi-error"
                          style="top:-1em"
                          > Penality percentage is required</label>

                        <label v-else for="pervalid">Enter the Penality percentage <span class="required">*</span></label>

                        </span>
                     </div>
                     <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <span class="has-float-label">
                        <input  :disabled="!isEdit" type="number" min="1" name="daysval" v-validate="'required|numeric|min:1|max:15'" v-model="penality.paidAfter" class="form-control form-input" id="daysvalid"  >
                        
                        <label
                          v-if="errors.first('crtfeeplanValidate.daysval')"
                          class="vi-error"
                          style="top:-1em"
                          > Paid after is required</label
                        > 
                        <label v-else for="pervalid">Paid after <span class="required">*</span></label>
                        </span>
                     </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">						
                        <label for="daysvalid">Days from Invoice Due Date </label>
                     </div>
                    
                  </div>
              </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
           
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                v-if="editId && isEdit"
                @click.prevent="updateViewFeePlan"
              >
                  <span>Update</span>
              </button>
              
              
              <button
                @click.prevent="closeViewEditPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
     </b-modal>
     <b-modal
      id="viewPlan"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closeViewPopUp">×</button>

      <div class="contact-form">
        <h2> View Fee Plan</h2>
        <br />
        <div>
         
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="fpnssame"
                    v-model="feePlan.planName"
                    class="form-control form-input"
                    id="fpnameid"
                    placeholder="Fee Plan Name"
                  />
                 
                  <label  for="fpnameid"
                    >Fee Plan Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    v-model="feePlan.academicYear"
                    class="form-control form-input"
                    id="acyearid"
                    disabled
                    name="acdyr"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
               
                  <label  for="acyearid"
                    >Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    disabled
                    onkeydown="return false" 
                    name="fpstdsdsdsartdate"
                    v-model="feePlan.feesStartDate"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="Fee Plan State Date"
                  />

                 
                  <label for="fpstartdateid"
                    >Fee Plan Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    disabled
                    onkeydown="return false" 
                    v-model="feePlan.feesEndDate"
                    class="form-control form-input"
                    id="fpenddateid"
                    placeholder="Fee Plan End Date"
                  />
                 
                  <label  for="fpenddateid"
                    >Fee Plan End Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    name="duedate"
                    disabled
                    onkeydown="return false" 
                    v-model="feePlan.feesDueDate"
                    class="form-control form-input"
                    id="duedateid"
                    placeholder="Fee Plan End Date"
                  />

                 
                  <label  for="duedateid"
                    >Fee Plan Activation Date <span class="required">*</span></label
                  >
                </span>
              </div> -->
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                      class="form-control form-input"
                      name="PlanType"
                      v-model="feePlan.type"
                      >
                      <option :value=null>--Select--</option>
                      <option v-for="(item,index) in types" :key="index" :value="item.value">
                        {{ item.label }} 
                      </option>
                      </select>
                       
                        <label   >Plan type <span class="required">*</span></label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                   <v-select class="form-control form-input"
                      label="label"
                      name="AssignClasses"
                      multiple
                      disabled
                      v-model="feePlan.classes"
                      :options="getformatAllClassList"
                      :reduce="label => label.value"
                    ></v-select>
                  <label for="selclslistid"
                    >Select Class <span class="required">*</span></label
                  >
                </span>
              </div>
              
               <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    type="text"
                    disabled
                    v-model="totalCountStudents"
                    name="fpstarffvvbtdate"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <label  for="fpstartdateid"
                    >Total Count of Students </label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 mt-3 pr-md-3" >
                <span class="has-float-label">
                  <input v-if="status == 'Draft'"
                    type="text"
                    disabled
                    style="color: brown;"
                    v-model="status"
                    name="fpstartdatedddf3"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />
                  <input v-else
                    type="text"
                    disabled
                    style="color: green;"
                    v-model="status"
                    name="fpstartdateghghf4"
                    class="form-control form-input"
                    id="fpstartdateid"
                    placeholder="status"
                  />

                 
                  <label  for="fpstartdateid"
                    >Status </label
                  >
                </span>
              </div>
            </div>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
           
            <div class="text-center dk_iconsml">
              
              <button
                @click.prevent="closeViewPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
     </b-modal>
     <b-modal id="deleteFeeTypePopup" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
           @click.prevent="confirmDelete"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
     <b-modal id="actionFeeTypePopup" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeActionPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>{{ updateActionType == 'Approved' ? 'Approve' : 'Reject' }} Confirmation</h2>
        <br />
        Are you sure you want to {{ updateActionType == 'Approved' ? 'Approve' : 'Reject' }} this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
           @click.prevent="actionConfirm"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeActionPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
     <b-modal id="selectApprovePopup" style="width: 40%" size="sm" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeReportingPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Reporting To </h2>
        <br />
         <div class="form-row">
        <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="Reportingid"
                    v-model="reportingTo"
                    name="Reporting"
                  >
                    <option :value="null">-- Select User--</option>
                    <option
                      v-for="(item, index) in internalUserList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.firstName + " " + item.lastName }} ( {{ item.role.name }} )
                    </option>
                  </select>
               
                  <label  for="acyearid"
                    >Reporting User <span class="required">*</span></label
                  >
                </span>
              </div>
               </div>
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button :disabled="!reportingTo"
            @click.prevent="confirmSubmitForApproval"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeReportingPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
     <b-modal id="viewClassPopUp" no-close-on-backdrop no-close-on-esc >
      <!-- <div class="modal-content"> -->
        <button type="button" class="close"  @click.prevent="closeViewClassPopUp">×</button>

        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>View  </h2>
        
          <div >

             
               <div class="row mt-4">
            <div class="col-lg-12">
              <div class="widjet">
                <div class=" tbllist">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">S.No </div>
                     <div class="cell">Class Name </div>
                    </div>
                    <div class="resrow" v-for="(data, index) in viewClassIds" :key="index">
                       <div class="cell" data-title="index">{{ index + 1 }}</div>
                      <div class="cell" data-title="DepartMent">{{ getClassNameForView(data) }}</div>
                    
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
               </div>
             
              <div
                class="pt-3 text-center dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                
                <button @click.prevent="closeViewClassPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
          </div>
        </div>
      <!-- </div> -->
     </b-modal>
     <b-modal id="showApplyTermsPopUp" no-close-on-backdrop no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeTermsPoup">×</button>

        <div class="contact-form">
          <h2>Select Terms Apply to  </h2>
        
          <div>
               <div class="row mt-4">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjetcontent">
                 <div class="form-row">
                            <div class="form-group input-group col-md-12 mt-3" >
                              <span class="has-float-label">
                                 <v-select class="form-control form-input"
                                    label="label"
                                    multiple
                                    v-model="multiselectForTerm"
                                    id="deptlistid"
                                    :options="applyToList"
                                    :reduce="label => label.value"
                                  ></v-select>

                                <label for="t1invdateid"
                                  >Apply To
                                  <span class="required">*</span></label
                                >
                              </span>
                             </div>
                              </div>
                </div>
               
              </div>
            </div>
               </div>
             
              <div
                class="pt-3 text-right dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="confirmSelectedTerms"
                    
                  >
                      <span>Confirm</span>
                  </button>
                  <button  @click.prevent="closeTermsPoup" type="button" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
              </div>
          </div>
        </div>
     </b-modal>
  </div>
   <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-fees-management",
  data() {
    return {
      feePlan: {
        planName: "",
        type: 'Term-Fees',
        academicYear: null,
        feesStartDate: null,
        feesEndDate: null,
        feesDueDate: null,
        classes : [],
      },
      count: {
        feePlanCount : 0,
        feePlanApprovedCount : 0,
        assignFeeTypesCount : 0,
        discountsAndPenaltiesCount : 0,
        assignFeeToStudentsCount : 0
      },
      assignedFeeType: {
        billingFrequency: '',
        isRefundable : false,
        feesPlanId : null,
        feeTermEvery: '',
        feeInvoiceDate: '',
        feeDueDate: '',
        feesTerms : [],
        oneTimeFrequency: []
		  },
      assignedFeesTypeList : [],
      oneTimebillingFrequencyList: [
        'Recurring',
        'Fixed Fee'
      ],
      modeList: [
        "Rs($)",
        "Percentage(%)"
      ],
      showPenality: false,
      showDiscount: false,
      isFeePlanNameAvailable: true,
      termList:[],
      termsEvery: [],
      invoiceDates: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31'
      ],
      invoiceDueDates: [{
        label: '1 day',
        value: '1'
      },
      {
        label: '2 days',
        value: '2'
      },
      {
        label: '3 days',
        value: '3'
      },
      {
        label: '4 days',
        value: '4'
      },
      {
        label: '5 days',
        value: '5'
      },
      {
        label: '6 days',
        value: '6'
      },
      {
        label: '7 days',
        value: '7'
      },
      {
        label: '8 days',
        value: '8'
      },
      {
        label: '9 days',
        value: '9'
      },
      {
        label: '10 days',
        value: '10'
      },
      {
        label: '11 days',
        value: '11'
      },
      {
        label: '12 days',
        value: '12'
      },
      {
        label: '13 days',
        value: '13'
      },
      {
        label: '14 days',
        value: '14'
      },
      {
        label: '15 days',
        value: '15'
      },
      {
        label: '16 days',
        value: '16'
      },
      {
        label: '17 days',
        value: '17'
      },
      {
        label: '18 days',
        value: '18'
      },
      {
        label: '19 days',
        value: '19'
      },
      {
        label: '20 days',
        value: '20'
      },
      {
        label: '21 days',
        value: '21'
      },
      {
        label: '22 days',
        value: '22'
      },
      {
        label: '23 days',
        value: '23'
      },
      {
        label: '24 days',
        value: '24'
      },
      {
        label: '25 days',
        value: '25'
      },
      {
        label: '26 days',
        value: '26'
      },
      {
        label: '27 days',
        value: '27'
      },
      {
        label: '28 days',
        value: '28'
      },
      {
        label: '29 days',
        value: '29'
      },
      {
        label: '30 days',
        value: '30'
      },
      {
        label: '31 days',
        value: '31'
      }
      ],  
      editId: null,
      noOfMonths: 0,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 6, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      deleteId: null,
      academicYearList: [],
      viewClassIds: [],
      classList: [],
      minPlanDate: null,
      maxPlanDate: null,
      minMonth: false,
      minFromPlanStartDate: null,
      allClassList: [],
      feesPlanList: [],
      allPlanList: [],
      status: 'Draft',
      updateActionId: null,
      updateActionType: '',
      reportingTo: null,
      isFeesTypeAvailable: true,
      selectAcademicYear: null,
      reportingToEditId: null,
      totalCountStudents: 0,
      feetypeAmountLength: 0,
      role: {},
      isEdit: false,
      preFeePlanName: '',
      assignedTermsIndex: null,
      viLoader: false,
      assignFeeEditId: null,
      multiselectForTerm: [],
      internalUserList: [],
      feesTypeList: [],
      billingFrequencyList: [],
      discount: {
        mode: 'Rs($)',
        discountAmountValue: '',
        discountPercentageValue: '',
        termPaid: 'Paid in full term'
      },
      penality: {
        mode: 'Rs($)',
        penaltyAmountValue: '',
        penaltyPercentageValue: '',
        paidAfter: null
      },
      types: [
        {
         label: 'Fee Term',
         value: 'Term-Fees'
        }
      ]
    };
  },
  created() {
    this.getAcademicYearList();
    this.initMethod()
    this.getFeesPlanList();
    this.getFeesPlanListAll()
    this.getInternalUserList();
    this.objectCounts();
    this.getFeesTypeList()
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getformatClassList() {
        if(this.classList.length > 0){
          return this.classList.map(x => {
          return {
            label: x.className,
            value: x._id
          };
        });
        }else{
          return []
        }
       
      } ,
      checkFeetypeDiscount(){
       if(this.assignedFeeType.oneTimeFrequency && this.assignedFeeType.oneTimeFrequency.length > 0){

        const checkdata = this.assignedFeeType.oneTimeFrequency.filter( x => x.isDiscount)
        if(checkdata.length > 0){
          return false

        }else{
        return true
       }

       }else{
        return true
       }
      },
      getformatAllClassList() {
        if(this.allClassList.length > 0){
          return this.allClassList.map(x => {
          return {
            label: x.className,
            value: x._id
          };
        });
        }else{
          return []
        }
       
      },
      getUserId(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails._id
      },
       applyToList() {
        const checkTermList = []

        for(let x of this.termList){
           if(x.feesStartDate && x.feesStartDate){
             checkTermList.push(x)
           }
        }
        
        return checkTermList.map(x => {
        return {
          label: x.term,
          value: x.term
        };
       });

      }
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                   this.getFeesPlanList();
              }
          }
  },
  methods: {
    previousRoute(){
    let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },

    async openFreePlanCreationScreen() {
      this.feePlan.planName = "";
      this.feePlan.type = "Term-Fees";
      this.feePlan.status = ""
      this.feePlan.academicYear = null;
      this.feePlan.feesStartDate = null;
      this.feePlan.feesEndDate = null;
      this.feePlan.feesDueDate = null;
    	this.feePlan.classes = [];
      this.$bvModal.show("crtfeeplan");
    },

    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getFeesPlanList();
    },
    clearInvoiceDate(){
         this.assignedFeeType.feeDueDate = ''
    },
    checkFeePlanName(){
     let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {

        let allowDupUser = true;


        if (this.editId) {

          // check the current key value is changes
          if ( this.feePlan.planName == this.preFeePlanName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
          
          const details = this.allPlanList.find((x) => x.planName == this.feePlan.planName);
           if(details){
              this.isFeePlanNameAvailable = false
           }else{
              this.isFeePlanNameAvailable = true
           }
        }else{
          this.isFeePlanNameAvailable = true
        }
        }
    },
    moveRightSide(){
    document.getElementById('content').scrollLeft += 222;
    },
    moveLeftSide(){
    document.getElementById('content').scrollLeft -= 222;
    },
    clearEndDate(value,index){
      
      this.termList[index].feesEndDate = null

      if (value == '' || value == null || value == undefined) {
          this.termList.forEach((data,i) => {
            if (i == index) {
              data.feesEndDate = null;
            } 
          });
        }
    
    },
    clearPenality(){
        this.penality.penaltyAmountValue = '',
        this.penality.penaltyPercentageValue = ''
    },
    cleardiscount(){
        this.discount.discountAmountValue = '',
        this.discount.discountPercentageValue = ''
    },
    setDueDates(){
    
      for(let x of this.termList){
        let someDate = new Date(x.feesStartDate);
        let numberOfDaysToAdd = Number(this.assignedFeeType.feeInvoiceDate) - 1;
        x.feesStartDate = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
        
        if(x.term == 'Term 1'){
           x.feesStartDate = new Date(x.feesStartDate.setDate(x.feesStartDate.getDate() + 1));
        }

        let planDate = new Date(this.feePlan.feesEndDate);

        if(x.feesStartDate > planDate){
              x.feesStartDate = this.checkAndSetCurrentDate(planDate)
        }else{
             x.feesStartDate = this.checkAndSetCurrentDate(x.feesStartDate)
        }

      }

      for(let x of this.termList){
        let someDate = new Date(x.feesStartDate);
        let numberOfDaysToAdd = Number(this.assignedFeeType.feeDueDate);
        x.feesEndDate = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));

       let planDate = new Date(this.feePlan.feesEndDate);

        if(x.feesEndDate > planDate){
          x.feesEndDate = this.checkAndSetCurrentDate(planDate)
        }else{
          x.feesEndDate = this.checkAndSetCurrentDate(x.feesEndDate)
        }
      }
    },
    setTermsAmount(){
      for(let x of this.termList){
          x.amount = 0
      }

      for(let x of this.assignedFeeType.oneTimeFrequency){
        if(x.amount){
        for(let y of x.term){
          for(let z of this.termList){
            if(z.term == y){
              z.amount = Number(x.amount) + z.amount
            }
          }
        }
      }
      
      }

    },
    async addFeeTypes(){
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
        isFormValid = result;
      });

		if(isFormValid) {

      this.assignedFeeType.oneTimeFrequency.push({
            feeTypeId: null,
            billingFrequency: null,
            isRefundable : false,
            term : '',
            isDiscount: false,
            amount: null
        })
    }else{
        this.$toasted.error(message);
    }
    },
    removeLineItem(index){
		  this.assignedFeeType.oneTimeFrequency.splice(index,1);
     this.checkValue('check')
      //for(let x of )
     this.setTermsAmount()
	  },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getFeesPlanList();
    },
    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getFeesPlanList();
    },
    setApplyTerms(index){
    this.assignedTermsIndex = null,
    this.multiselectForTerm = [],
    this.assignedTermsIndex = index,
    this.multiselectForTerm =  this.assignedFeeType.oneTimeFrequency[index].term,
    this.$bvModal.show("showApplyTermsPopUp");
    },
    setfrequencyAndamount(value,index){
      this.assignedFeeType.oneTimeFrequency[index].term = ''
      this.assignedFeeType.oneTimeFrequency[index].amount = null

      if(value){
        if(value == 'Recurring'){
          const checkTermList = []

        for(let x of this.termList){
           if(x.feesStartDate && x.feesStartDate){
             checkTermList.push(x)
           }
        }
        if(checkTermList.length > 0){
        this.assignedFeeType.oneTimeFrequency[index].term = checkTermList.map( x => x.term)
        }

        }
      }
   
    },
    confirmSelectedTerms(){
    if(this.multiselectForTerm.length > 0){
      this.assignedFeeType.oneTimeFrequency[this.assignedTermsIndex].term = this.multiselectForTerm
      this.setTermsAmount()
      this.$bvModal.hide("showApplyTermsPopUp");
      this.assignedTermsIndex = null,
      this.multiselectForTerm = []
    }else{
      this.$toasted.error(`Please select. Minimum one term`);
    }
    
    },
    async getFeesTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/feesManagement/getFeesTypeList",
          userData.token
        );
        if (response.isSuccess) {
          this.feesTypeList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    closeTermsPoup(){
  this.assignedTermsIndex = null,
  this.multiselectForTerm = [],
  this.$bvModal.hide("showApplyTermsPopUp");
    },
    checkAlreadySelected(value,index){
    const getFeeTypes = this.assignedFeeType.oneTimeFrequency.filter( x => (x.feeTypeId == value.toString()) && x.feeTypeId != 'null' && x.feeTypeId != '' &&  x.feeTypeId != undefined)
    if(getFeeTypes.length > 1){
      this.assignedFeeType.oneTimeFrequency[index].feeTypeId = null
      this.$toasted.error(`Fee Type already in use, please choose a different Fee Type`);
    }
   
    },
    initMethod(){
     let userData = secureUI.sessionGet("user");
     this.role = userData.userDetails.role
    },
    getClassNameForView(data){
      if(data){
       const classDetails = this.allClassList.find( x => x._id == data)
       if(classDetails){
            return classDetails.className
       }else{
         return ''
       }
      }else{
        return ''
      }
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   
    gettotalAdhocFees(data) {
        if(data.length > 0){
          return data.map(o => o.netAmount).reduce((a, c) => { return a + c });
        }else{
          return 0
        }
       
    },
    updateAction(planId,actionType){
      this.updateActionId = planId,
      this.updateActionType = actionType
      this.$bvModal.show("actionFeeTypePopup");
    },
    setTerms(){

      if(this.assignedFeeType.billingFrequency){

         this.assignedFeeType.feeInvoiceDate = null,
         this.assignedFeeType.feeTermEvery = '',
         this.assignedFeeType.feeInvoiceDate = '',
         this.assignedFeeType.feeDueDate = '',
         this.assignedFeeType.isRefundable = false,
         this.assignedFeeType.feesTerms = [],
         this.termList = [],

        this.assignedFeeType.oneTimeFrequency = []

        if(this.assignedFeeType.billingFrequency != 'Custom'){
        
        let count = 0;

        if(this.assignedFeeType.billingFrequency == 'Monthly'){
            count = this.noOfMonths
        }else if(this.assignedFeeType.billingFrequency == 'Quarterly'){
            count = (this.noOfMonths/3)
        }else if(this.assignedFeeType.billingFrequency == 'Half Yearly' ){
            count = (this.noOfMonths/6)
        }
       
        let startDate = new Date(this.feePlan.feesStartDate);

        let currentDate = new Date()

        let finalStartDate = null
       
        if(currentDate > startDate){
             
               let addOneCurrentMonth = currentDate.getMonth() + 1

               if(addOneCurrentMonth < 10) {
                  addOneCurrentMonth = '0' + addOneCurrentMonth;
               }
               
               let getCurrentDate = currentDate.getDate()

               if(getCurrentDate < 10) {
                  getCurrentDate = '0' + getCurrentDate;
               }

              finalStartDate =  currentDate.getFullYear() + "-" + addOneCurrentMonth + "-" + getCurrentDate;
        }else{
          
         finalStartDate = this.feePlan.feesStartDate;
         
        }

        let nextMonth = 0

        if(count == 12){
          nextMonth = 1
        }

        if(count == 4){
          nextMonth = 3
        }

         if(count == 6 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 7 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 8 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 9 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 10 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 11 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }

        if(count == 2){
          nextMonth = 6
        }

        if(count == 2 && this.assignedFeeType.billingFrequency == 'Quarterly'){
          nextMonth = 3
        }

        if(count == 3 && this.assignedFeeType.billingFrequency == 'Quarterly'){
          nextMonth = 3
        }
        
        for(let i = 0; i < count; i++) {

            this.termList.push({
              term: `Term ${i + 1 }`,
              feesStartDate: null, 
              minStartDate: finalStartDate,
              maxStartDate: this.feePlan.feesEndDate,
              feesEndDate: null,
              amount: null
          })
         
        }
        }else{

        let startDate = new Date(this.feePlan.feesStartDate);

        let currentDate = new Date()

        let finalStartDate = null

        if(currentDate > startDate){
          let startDate = new Date();
          let endDate = new Date(this.feePlan.feesEndDate);

          let months = 0;

          months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
          months -= startDate.getMonth();
          months += endDate.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
             
        }else{
          let endDate = new Date(this.feePlan.feesEndDate);

          let months = 0;

          months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
          months -= startDate.getMonth();
          months += endDate.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
        }

          this.termsEvery = []
          for(let i = 0; i < this.noOfMonths; i++) {

             if(i == 0){
              this.termsEvery.push({
              label: i + 1 + " " + 'Month',
              value: i + 1
            })
            }else{
             this.termsEvery.push({
              label: i + 1 + " " + 'Months',
              value: i + 1
            })
            }

          }
        }
      }else{
         this.assignedFeeType.feeInvoiceDate = null,
         this.assignedFeeType.feeTermEvery = '',
         this.assignedFeeType.feeInvoiceDate = '',
         this.assignedFeeType.feeDueDate = '',
         this.assignedFeeType.isRefundable = false,
         this.assignedFeeType.feesTerms = [],
         this.termList = [],
         this.assignedFeeType.oneTimeFrequency = []
      }
      
    },
    setPlanDate(acdyear,actionType){
      if(acdyear){
        
        const selectAcdYear = this.academicYearList.find( x => x._id == acdyear);
        
        let start = new Date(selectAcdYear.from)
        let end = new Date(selectAcdYear.to)
         // start date 
        let startmonth = start.getMonth()+1
        let startdate = start.getDate()
        

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
         // startmonth  = startmonth + 1
          startmonth = '0' + startmonth;
        }
        

        this.minPlanDate =  start.getFullYear() + "-" + startmonth + "-" + startdate;
       // end date
        let endmonth = end.getMonth()+1

        let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();


        if (endmonth < 10) {
          endmonth = '0' + endmonth;
        }
        
        this.maxPlanDate =  end.getFullYear() + "-" + endmonth + "-" + enddate;

        if(actionType == 'edit'){
         this.feePlan.classes = [];
         this.feePlan.feesEndDate = null;
         this.termList = [],
         this.assignedFeeType.feeInvoiceDate = null,
         this.assignedFeeType.billingFrequency = '',
         this.assignedFeeType.feeTermEvery = '',
         this.assignedFeeType.feeInvoiceDate = '',
         this.assignedFeeType.feeDueDate = '',
         this.assignedFeeType.isRefundable = false,
         this.assignedFeeType.feesPlanId = null,
         this.assignedFeeType.feesTerms = [],
         this.termList = [],
         this.assignedFeeType.oneTimeFrequency = []
         this.isFeesTypeAvailable = true

         this.totalCountStudents = 0

          const unSelectedClass = []
          const admissionClasIds = []

          // this.classList = []

          // if(this.allPlanList.length > 0){

          //   const filterByAcdyear = this.allPlanList.filter( x => x.academicYear == acdyear)

          //   if(filterByAcdyear.length > 0){
          //   for(let x of filterByAcdyear){
          //     if(x.classes && x.classes.length > 0){
          //       for(let y of x.classes){
          //       admissionClasIds.push(y)
          //       }
          //     }
          //   }
          //  }else{
          //    this.classList = this.allClassList;
          //  }

          // }
          // if(admissionClasIds.length > 0){ 

          //   for(let x of this.allClassList){

          //     if(admissionClasIds.indexOf(x._id) == -1 && unSelectedClass.indexOf(x._id) == -1){
          //      this.classList.push(x)
          //      unSelectedClass.push(x._id)
          //     }

          //   }
          // }else{
          //   this.classList = this.allClassList;
          // }

        }
        
      }else{
        
         this.feePlan.feesStartDate = null;
         this.feePlan.feesEndDate = null;
         this.feePlan.feesDueDate = null
         this.feePlan.classes = [];
         this.classList = []
         this.termList = [],
         this.assignedFeeType.feeInvoiceDate = null,
         this.assignedFeeType.billingFrequency = '',
         this.assignedFeeType.feeTermEvery = '',
         this.assignedFeeType.feeInvoiceDate = '',
         this.assignedFeeType.feeDueDate = '',
         this.assignedFeeType.isRefundable = false,
         this.assignedFeeType.feesPlanId = null,
         this.assignedFeeType.feesTerms = [],
         this.termList = [],
         this.assignedFeeType.oneTimeFrequency = []
         this.isFeesTypeAvailable = true
      }
    },
    setPlanEndDate(startDatePlan){
    this.minFromPlanStartDate = startDatePlan
    this.feePlan.feesEndDate = null
    this.minMonth = false
    },
    checkAndSetCurrentDate(startCurrentDate){
            let getCurrentYear = startCurrentDate.getFullYear()
            let getCurrentMonth = startCurrentDate.getMonth() + 1
            let getCurrentDate = startCurrentDate.getDate()
            
            if (getCurrentMonth < 10) {
              getCurrentMonth = '0' + getCurrentMonth;
            }

            if (getCurrentDate < 10) {
              getCurrentDate = '0' + getCurrentDate;
            }

            return getCurrentYear + "-" + getCurrentMonth + "-" + getCurrentDate;
    },
    setCustomTerms(){
      this.termList = [],
      this.assignedFeeType.feeInvoiceDate = null,
      this.assignedFeeType.feeDueDate = ''

     if(this.assignedFeeType.feeTermEvery){

        let startCurrentDate = null

        let planDate = new Date(this.feePlan.feesStartDate);

        let currentDate = new Date()


        if(currentDate > planDate){
             startCurrentDate = currentDate
        }else{
           startCurrentDate = planDate;
        }

        let endDate = new Date(this.feePlan.feesEndDate);
        let finalcurrentDate = null
        let months = 0;

        months = (endDate.getFullYear() - startCurrentDate.getFullYear()) * 12;
        months -= startCurrentDate.getMonth();
        months += endDate.getMonth() + 1;
        

        if(months == 13){
            months = months - 1
        }


        this.noOfMonths = months

        const fixedOneDecimal = (this.noOfMonths/this.assignedFeeType.feeTermEvery).toFixed(1)

        const customTermsCount = Math.round(fixedOneDecimal)

        if(customTermsCount){

        this.termList = [];
        
        this.assignedFeeType.oneTimeFrequency = []

        let count =  customTermsCount;

     
        let startDate = new Date();

        let nextMonth = this.assignedFeeType.feeTermEvery

        for(let i = 0; i < count; i++) {
            
           if(this.termList.length != 0){

            startDate = startCurrentDate

            startDate.setMonth(startDate.getMonth() + nextMonth);
            let newMonth = startDate.getMonth()+1
            
            if (newMonth < 10) {
              newMonth = '0' + newMonth;
            }

            startDate =  startDate.getFullYear() + "-" + newMonth + "-" + '01';
           }else{

            let getCurrentYear = startCurrentDate.getFullYear()
            let getCurrentMonth = startCurrentDate.getMonth()+1
            let getCurrentDate = startCurrentDate.getDate()
            
            if (getCurrentMonth < 10) {
              getCurrentMonth = '0' + getCurrentMonth;
            }

            if (getCurrentDate < 10) {
              getCurrentDate = '0' + getCurrentDate;
            }


            finalcurrentDate =  getCurrentYear + "-" + getCurrentMonth + "-" + getCurrentDate;
           }

            this.termList.push({
              term: `Term ${i + 1 }`,
              feesStartDate: this.termList.length == 0 ? finalcurrentDate : startDate, 
              minStartDate: finalcurrentDate,
              maxStartDate: this.feePlan.feesEndDate,
              feesEndDate: null,
              amount: null
          })
         
        }
        
        }
     }
    },
    checkValue(value){
      if(this.assignedFeeType.oneTimeFrequency.length > 0){
        let checkDiscounts = this.assignedFeeType.oneTimeFrequency.filter( x => x.isDiscount)
      if(checkDiscounts.length > 0){
        this.showDiscount = true

      }else{
        this.showDiscount = false

      }
      }else{
        this.showDiscount = false
      }
     

    },
    checkEndDateValidate(startDate,endDate){
      if(endDate){
        var checkcurrentDate = new Date()
        var checkEndDate = new Date(endDate)
        if(checkEndDate > checkcurrentDate){

        this.billingFrequencyList = []
        this.assignedFeeType.billingFrequency = '',
        this.assignedFeeType.feeTermEvery = '',
        this.assignedFeeType.feeInvoiceDate = '',
        this.assignedFeeType.feeDueDate = '',
        this.assignedFeeType.isRefundable = false,
        this.assignedFeeType.feesPlanId = null,
        this.assignedFeeType.feesTerms = [],
        this.termList = [],
        this.assignedFeeType.oneTimeFrequency = []
        this.isFeesTypeAvailable = true
        
        this.minMonth = false

        var currentDate = new Date()
        var d1 = null
        var d2 = new Date(endDate)
        var planStartDate = new Date(startDate)

        if(currentDate > planStartDate){
             d1 = new Date()
        }else{
          
            d1 = new Date(startDate)
         
        }


       // let getMonths = months <= 0 ? 0 : months

       
           this.billingFrequencyList = [{
            label: 'Monthly',
            value: 'Monthly',
            days: 30
          }];


          let months = 0;

          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
         
           if(months % 3 == 0) {
             this.billingFrequencyList.push( 
               {
                  label: 'Quarterly',
                  value: 'Quarterly',
                  days: 30
               })

          } 

          if (months % 6 == 0 ) {
          this.billingFrequencyList.push( 
              {
                label: 'Half Yearly',
                value: 'Half Yearly',
                days: 30
              })
          } 

          this.billingFrequencyList.push({
            label: 'Custom',
            value: 'Custom',
            days: 30
          })
        }else{
          this.feePlan.feesEndDate = null
          this.$toasted.error("The fee plan end date must be a future date.");
        }
      }else{
        this.billingFrequencyList = []
        this.assignedFeeType.billingFrequency = '',
        this.assignedFeeType.feeTermEvery = '',
        this.assignedFeeType.feeInvoiceDate = '',
        this.assignedFeeType.feeDueDate = '',
        this.assignedFeeType.isRefundable = false,
        this.assignedFeeType.feesPlanId = null,
        this.assignedFeeType.feesTerms = [],
        this.termList = [],
        this.assignedFeeType.oneTimeFrequency = []
        this.isFeesTypeAvailable = true
      }
       
       
    },
    closePoup() {
      
      this.$bvModal.hide("crtfeeplan");

      this.editId = null;
      this.isEdit = false
      this.showPenality = false,
      this.showDiscount = false,
      this.feePlan.planName = "";
      this.feePlan.academicYear = null;
      this.feePlan.feesStartDate = null;
      this.feePlan.feesEndDate = null;
      this.feePlan.feesDueDate = null;
    	this.feePlan.classes = [];
      this.totalCountStudents = 0
      this.feetypeAmountLength = 0
     
      this.discount.mode = 'Rs($)',
      this.discount.discountAmountValue = '',
      this.discount.discountPercentageValue = '',
      this.discount.termPaid = 'Paid in full term',
      this.penality.mode = 'Rs($)',
      this.penality.penaltyAmountValue = '',
      this.penality.penaltyPercentageValue = '',
      this.penality.paidAfter = null
      this.closeAssignFeeType()
      
    },
    isTrueEnable(){
       this.isEdit = true
    },
    closeViewEditPopUp(){
      this.$bvModal.hide("viewEditfeeplan");

      this.editId = null;
      this.isEdit = false,
      this.showPenality = false,
      this.showDiscount = false,
      this.feePlan.planName = "";
      this.feePlan.academicYear = null;
      this.feePlan.feesStartDate = null;
      this.feePlan.feesEndDate = null;
      this.feePlan.feesDueDate = null;
      this.feePlan.classes = [];
      this.totalCountStudents = 0
      this.feetypeAmountLength = 0

      this.discount.mode = 'Rs($)',
      this.discount.discountAmountValue = '',
      this.discount.discountPercentageValue = '',
      this.discount.termPaid = 'Paid in full term',
      this.penality.mode = 'Rs($)',
      this.penality.penaltyAmountValue = '',
      this.penality.penaltyPercentageValue = '',
      this.penality.paidAfter = null
      this.closeAssignFeeType()

    },
    closeViewPopUp(){
      this.totalCountStudents = 0
      this.editId = null;
       this.$bvModal.hide("viewPlan");
    },
    closeAssignFeeType(){
      this.assignedFeeType.billingFrequency = '',
      this.assignedFeeType.feeTermEvery = '',
      this.assignedFeeType.feeInvoiceDate = '',
      this.assignedFeeType.feeDueDate = '',
      this.assignedFeeType.isRefundable = false,
      this.assignedFeeType.feesPlanId = null,
      this.assignedFeeType.feesTerms = [],
      this.termList = [],
      this.assignedFeeType.oneTimeFrequency = []
      this.isFeesTypeAvailable = true
    },
    previousStep(action){

      if( action == 'first'){
       this.$bvModal.show("crtfeeplan");
      }else{
        this.$bvModal.show("crtfeesType");
      }
     
    },
    closeActionPopUp(){
      this.$bvModal.hide("actionFeeTypePopup");
      this.updateActionId = null,
      this.updateActionType = null
    },
    closeReportingPopUp(){
      this.$bvModal.hide("selectApprovePopup");
      this.reportingTo = null,
      this.reportingToEditId = null,
      this.editId = null;
      this.isEdit = false
      this.feePlan.planName = "";
      this.feePlan.academicYear = null;
      this.feePlan.feesStartDate = null;
      this.feePlan.feesEndDate = null;
      this.feePlan.feesDueDate = null;
    	this.feePlan.classes = []
    },
    async actionConfirm(index){

      const planDetails = this.feesPlanList.find( x => x._id == this.updateActionId);
      this.editId = planDetails._id;
      this.feePlan.planName = planDetails.planName;
      this.feePlan.academicYear = planDetails.academicYear;
      this.feePlan.feesStartDate = planDetails.feesStartDate.substring(0,10);
      this.feePlan.feesEndDate = planDetails.feesEndDate.substring(0,10);
      this.feePlan.feesDueDate = planDetails.feesDueDate.substring(0,10);
    	this.feePlan.classes = planDetails.classes || []
      this.feePlan.type = planDetails.type,
      this.feePlan.status = this.updateActionType
      this.feePlan.reportingTo = null
      
      let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(
            `/feesManagement/updateAction/${this.editId}`,
            this.feePlan,
            userData.token
          );

          if (response.isSuccess) {
             this.editId = null;
             this.isEdit = false,
             this.closeActionPopUp();
             this.closePoup();
             this.getFeesPlanList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
    editPlan(index,type){

      const cloneFeePlanList = [...this.feesPlanList ]

      const feeplanDetails = cloneFeePlanList[index];

      const planDetails = JSON.parse(JSON.stringify(feeplanDetails))
  
      this.editId = planDetails._id;
      this.assignFeeEditId = planDetails.assignFee._id
      this.feePlan.planName = planDetails.planName;
      this.preFeePlanName = planDetails.planName;
      this.feePlan.academicYear = planDetails.academicYear;
      this.feePlan.feesStartDate = planDetails.feesStartDate.substring(0,10);
      this.feePlan.feesEndDate = planDetails.feesEndDate.substring(0,10);
      this.feePlan.feesDueDate = planDetails.feesDueDate.substring(0,10);
    	this.feePlan.classes = planDetails.classes || []
      this.feePlan.type = planDetails.type,
      this.status = planDetails.status

      this.getAllStudentsCount()

      if(type == 'edit'){

        if(planDetails.discount){
          this.discount = planDetails.discount
        }
        
        if(planDetails.showPenality){
          this.penality = planDetails.penalty
        }
        this.showPenality = planDetails.showPenality || false
        this.showDiscount = planDetails.showDiscount || false

        this.assignedFeeType.billingFrequency = planDetails.assignFee.billingFrequency,
        this.assignedFeeType.feeTermEvery = planDetails.assignFee.feeTermEvery,
        this.assignedFeeType.feeInvoiceDate = planDetails.assignFee.feeInvoiceDate,
        this.assignedFeeType.feeDueDate = planDetails.assignFee.feeDueDate,
        this.assignedFeeType.isRefundable = planDetails.assignFee.isRefundable,
        this.assignedFeeType.feesPlanId = planDetails.assignFee.feesPlanId,
        this.assignedFeeType.feesTerms = planDetails.assignFee.feesTerms

        var d1 = new Date(this.feePlan.feesStartDate)
        var d2 = new Date(this.feePlan.feesEndDate)

        let months = 0;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();

        this.billingFrequencyList = [{
            label: 'Monthly',
            value: 'Monthly',
            days: 30
          }
         ]

          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
         
           if(months % 3 == 0) {
             this.billingFrequencyList.push( 
               {
                  label: 'Quarterly',
                  value: 'Quarterly',
                  days: 30
               })

          } 

          if (months % 6 == 0 ) {
          this.billingFrequencyList.push( 
              {
                label: 'Half Yearly',
                value: 'Half Yearly',
                days: 30
              })
          }

          this.billingFrequencyList.push({
            label: 'Custom',
            value: 'Custom',
            days: 30
          })

          this.termsEvery = []

          for(let i = 0; i < this.noOfMonths; i++) {
            if(i == 0){
              this.termsEvery.push({
              label: i + 1 + " " + 'Month',
              value: i + 1
            })
            }else{
             this.termsEvery.push({
              label: i + 1 + " " + 'Months',
              value: i + 1
            })
            }
            

          }

        this.assignedFeeType.oneTimeFrequency = planDetails.assignFee.oneTimeFrequency

        for (let x of this.assignedFeeType.feesTerms){

          this.termList.push({

            amount: x.amount || null,
            feesEndDate: x.feesEndDate ? x.feesEndDate.substring(0,10) : null,
            feesStartDate: x.feesStartDate ? x.feesStartDate.substring(0,10) : null,
            minStartDate: x.minStartDate ? x.minStartDate.substring(0,10) : null,
            maxStartDate: x.maxStartDate ? x.maxStartDate.substring(0,10) : null,
            term: x.term

          })
        }
         // const unSelectedClass = []
          //const admissionClasIds = []
         // this.classList = []

          // if(this.allPlanList.length > 0){

          //   const filterByAcdyear = this.allPlanList.filter( x => x.academicYear == this.feePlan.academicYear && x._id != this.editId)

          //   if(filterByAcdyear.length > 0){
          //   for(let x of filterByAcdyear){
          //     if(x.classes && x.classes.length > 0){
          //       for(let y of x.classes){
          //       admissionClasIds.push(y)
          //       }
          //     }
          //   }
          //  }else{
          //    this.classList = this.allClassList;
          //  }

          // }
          // if(admissionClasIds.length > 0){ 

          //   for(let x of this.allClassList){

          //     if(admissionClasIds.indexOf(x._id) == -1 && unSelectedClass.indexOf(x._id) == -1){
          //      this.classList.push(x)
          //      unSelectedClass.push(x._id)
          //     }

          //   }
          // }else{
          //   this.classList = this.allClassList;
          // }

        this.$bvModal.show("crtfeeplan");
        this.setPlanDate(this.feePlan.academicYear,'setValue')
      }else{
       this.$bvModal.show("viewPlan");
      }
    },
    viewEditPlan(index,type){

      const cloneFeePlanList = [...this.feesPlanList ]

      const feeplanDetails = cloneFeePlanList[index];

      const planDetails = JSON.parse(JSON.stringify(feeplanDetails))
  
      this.editId = planDetails._id;
      this.assignFeeEditId = planDetails.assignFee._id
      this.feePlan.planName = planDetails.planName;
      this.preFeePlanName = planDetails.planName;
      this.feePlan.academicYear = planDetails.academicYear;
      this.feePlan.feesStartDate = planDetails.feesStartDate.substring(0,10);
      this.feePlan.feesEndDate = planDetails.feesEndDate.substring(0,10);
      this.feePlan.feesDueDate = planDetails.feesDueDate.substring(0,10);
    	this.feePlan.classes = planDetails.classes || []
      this.feePlan.type = planDetails.type,
      this.status = planDetails.status

      this.getAllStudentsCount()

      if(type == 'edit'){

        if(planDetails.discount){
          this.discount = planDetails.discount
        }
        
        if(planDetails.showPenality){
          this.penality = planDetails.penalty
        }
        this.showPenality = planDetails.showPenality || false
        this.showDiscount = planDetails.showDiscount || false

        this.assignedFeeType.billingFrequency = planDetails.assignFee.billingFrequency,
        this.assignedFeeType.feeTermEvery = planDetails.assignFee.feeTermEvery,
        this.assignedFeeType.feeInvoiceDate = planDetails.assignFee.feeInvoiceDate,
        this.assignedFeeType.feeDueDate = planDetails.assignFee.feeDueDate,
        this.assignedFeeType.isRefundable = planDetails.assignFee.isRefundable,
        this.assignedFeeType.feesPlanId = planDetails.assignFee.feesPlanId,
        this.assignedFeeType.feesTerms = planDetails.assignFee.feesTerms

        var d1 = new Date(this.feePlan.feesStartDate)
        var d2 = new Date(this.feePlan.feesEndDate)

       

        let months = 0;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();

        this.billingFrequencyList = [{
            label: 'Monthly',
            value: 'Monthly',
            days: 30
          }
         ]

          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
         
           if(months % 3 == 0) {
             this.billingFrequencyList.push( 
               {
                  label: 'Quarterly',
                  value: 'Quarterly',
                  days: 30
               })

          } 

          if (months % 6 == 0 ) {
          this.billingFrequencyList.push( 
              {
                label: 'Half Yearly',
                value: 'Half Yearly',
                days: 30
              })
          }

          this.billingFrequencyList.push({
            label: 'Custom',
            value: 'Custom',
            days: 30
          })

          this.termsEvery = []

          for(let i = 0; i < this.noOfMonths; i++) {
            if(i == 0){
              this.termsEvery.push({
              label: i + 1 + " " + 'Month',
              value: i + 1
            })
            }else{
             this.termsEvery.push({
              label: i + 1 + " " + 'Months',
              value: i + 1
            })
            }
            

          }

        this.assignedFeeType.oneTimeFrequency = planDetails.assignFee.oneTimeFrequency

        for (let x of this.assignedFeeType.feesTerms){

          this.termList.push({

            amount: x.amount || null,
            feesEndDate: x.feesEndDate ? x.feesEndDate.substring(0,10) : null,
            feesStartDate: x.feesStartDate ? x.feesStartDate.substring(0,10) : null,
            minStartDate: x.minStartDate ? x.minStartDate.substring(0,10) : null,
            maxStartDate: x.maxStartDate ? x.maxStartDate.substring(0,10) : null,
            term: x.term

          })
        }
          const unSelectedClass = []
          // const admissionClasIds = []
          // this.classList = []

          // if(this.allPlanList.length > 0){

          //   const filterByAcdyear = this.allPlanList.filter( x => x.academicYear == this.feePlan.academicYear && x._id != this.editId)

          //   if(filterByAcdyear.length > 0){
          //   for(let x of filterByAcdyear){
          //     if(x.classes && x.classes.length > 0){
          //       for(let y of x.classes){
          //       admissionClasIds.push(y)
          //       }
          //     }
          //   }
          //  }else{
          //    this.classList = this.allClassList;
          //  }

          // }
          // if(admissionClasIds.length > 0){ 

          //   for(let x of this.allClassList){

          //     if(admissionClasIds.indexOf(x._id) == -1 && unSelectedClass.indexOf(x._id) == -1){
          //      this.classList.push(x)
          //      unSelectedClass.push(x._id)
          //     }

          //   }
          // }else{
          //   this.classList = this.allClassList;
          // }

        this.$bvModal.show("viewEditfeeplan");
        this.setPlanDate(this.feePlan.academicYear,'setValue')
      }else{
       this.$bvModal.show("viewPlan");
      }
    },
    
    showDeleteModal(index){

      const feePlanDetails = this.feesPlanList[index]
      this.deleteId = feePlanDetails._id,
     
      this.$bvModal.show("deleteFeeTypePopup");

    },
    closeDeletePopUp(){
      this.$bvModal.hide("deleteFeeTypePopup");
      this.deleteId = null;
    },
    async getAllStudentsCount() {
      
      this.totalCountStudents = 0
      if(this.feePlan.classes.length > 0){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       //feePlan.academicYear 
       let obj = { classes: this.feePlan.classes,academicYear : this.feePlan.academicYear }
        const response = await ViService.viXPost("/feesManagement/getStudentsCount",obj,userData.token);

        if (response.isSuccess) {
          this.totalCountStudents = response.data
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viDelete(`/feesManagement/deleteFeePlan/${this.deleteId}`,userData.token);

        if (response.isSuccess) {
          this.closeDeletePopUp();
          this.getFeesPlanList();
          this.getFeesPlanListAll();
          this.$toasted.success(response.message);
          let getCounts = this.$store.getters.getFeesManagementObjCount
          getCounts.feesPlan = getCounts.feesPlan - 1

          this.$store.dispatch('setFeesManagementObjCount', getCounts);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getSelectAcademicYear(){

          if(this.selectAcademicYear){
            this.getFeesPlanList();
          }else{
            this.selectAcademicYear = null
		        this.getFeesPlanList();
          }

          this.skip = 0
          this.currentPageNumber = 1,
          this.lastPageNumber = 1,
          this.selected = 1,
          this.totalCount = 0,
          this.countAll = true
    },
    viewClass(ids){
      this.viewClassIds = ids
      this.$bvModal.show("viewClassPopUp");
    },
    closeViewClassPopUp(){
    this.$bvModal.hide("viewClassPopUp");
    },
    async getFeesPlanList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        
        const response = await ViService.viXGet(`/feesManagement/getFeesPlanListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}&academicYear=${this.selectAcademicYear}`,userData.token);

       
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);
           
          this.feesPlanList = resultData.feesPlanList
          
          if(this.feesPlanList.length == 1){
            this.objectCounts();
          }

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }

        
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
       // this.viLoader = false;
      }
    },
    async objectCounts() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(`/feesManagement/getFeesPlanCount?viewType=list`,userData.token);

        if (response.isSuccess) {

          const resultData = secureUI.secureGet(response.data);
          
          this.count.feePlanCount = resultData.feePlan,
          this.count.feePlanApprovedCount = resultData.feePlanApproved,
          this.count.assignFeeTypesCount = resultData.assignFeeTypes,
          this.count.discountsAndPenaltiesCount = resultData.discountsAndPenalties,
          this.count.assignFeeToStudentsCount = resultData.assignFeeToStudents
     
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getFeesPlanListAll() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       this.viLoader = true;
        const response = await ViService.viXGet("/feesManagement/getFeesPlanList?viewType=list",userData.token);
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);
           this.allPlanList = resultData
          
          this.getClassList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },
    async updateFeePlan() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeplanValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isFeePlanNameAvailable) {
         let isTermDateFormValid = false;
         let message = "Please fill all the required details";

          await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
            isTermDateFormValid = result;
          });
          if(isTermDateFormValid){
            if(this.assignedFeeType.oneTimeFrequency.length > 0){


                  const checkSelectTerm =  this.assignedFeeType.oneTimeFrequency.filter( x => x.term.length == 0 ) 
                  
                  if(checkSelectTerm.length == 0){

                    const termAmountCheck = this.termList.filter( x => x.amount == 0)

                    if(termAmountCheck.length == 0){
                      let userData = secureUI.sessionGet("user");
                      this.assignedFeeType.feesTerms =  this.termList;

                      if(this.showPenality){
                              this.feePlan['showPenality'] = this.showPenality
                              this.feePlan['penalty'] = this.penality
                      }else{
                          this.feePlan['showPenality'] = this.showPenality
                          this.feePlan['penalty'] = {}
                      }
                      if(this.showDiscount){
                            this.feePlan['showDiscount'] = this.showDiscount
                            this.feePlan['discount'] = this.discount
                      }else{
                        this.feePlan['showDiscount'] = this.showDiscount
                        this.feePlan['discount'] = {}
                      } 


                      let obj = {
                        plan: this.feePlan,
                        assignFee: this.assignedFeeType
                      }

                      if(!userData) {
                        this.$toasted.error("Please login and do the action");
                      } else {
                        const response = await ViService.viXPut(`/feesManagement/updateFeePlan/${this.editId}/${this.assignFeeEditId}`,obj,userData.token);

                      if (response.isSuccess) {
                        this.editId = null;
                        this.isEdit = false
                        this.assignFeeEditId = null;
                        this.closePoup();
                        this.getFeesPlanList();
                        this.$toasted.success(response.message);
                      } else {
                        this.$toasted.error(response.message);
                         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
                      }
                      }
                    }else{
                       this.$toasted.error('Please assign atleast one fee type to all the terms');
                    }  
                  }else{
                    this.$toasted.error('Please Select a Fee Term');
                  }
               

            }else{
             this.$toasted.error('Please add at least one fee Type');
            }
          }else{
           this.$toasted.error('Please check term dates');
          }
      } else {
        this.$toasted.error(message);
      }
    },
    async updateViewFeePlan() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeplanValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isFeePlanNameAvailable) {
         let isTermDateFormValid = false;
         let message = "Please fill all the required details";

          await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
            isTermDateFormValid = result;
          });
          if(isTermDateFormValid){
            if(this.assignedFeeType.oneTimeFrequency.length > 0){


                  const checkSelectTerm =  this.assignedFeeType.oneTimeFrequency.filter( x => x.term.length == 0 ) 
                  
                  if(checkSelectTerm.length == 0){

                    const termAmountCheck = this.termList.filter( x => x.amount == 0)

                    if(termAmountCheck.length == 0){
                      let userData = secureUI.sessionGet("user");
                      this.assignedFeeType.feesTerms =  this.termList;

                      if(this.showPenality){
                              this.feePlan['showPenality'] = this.showPenality
                              this.feePlan['penalty'] = this.penality
                      }else{
                          this.feePlan['showPenality'] = this.showPenality
                          this.feePlan['penalty'] = {}
                      }
                      if(this.showDiscount){
                            this.feePlan['showDiscount'] = this.showDiscount
                            this.feePlan['discount'] = this.discount
                      }else{
                        this.feePlan['showDiscount'] = this.showDiscount
                        this.feePlan['discount'] = {}
                      } 


                      let obj = {
                        plan: this.feePlan,
                        assignFee: this.assignedFeeType
                      }

                      if(!userData) {
                        this.$toasted.error("Please login and do the action");
                      } else {
                        const response = await ViService.viXPut(`/feesManagement/updateFeePlan/${this.editId}/${this.assignFeeEditId}`,obj,userData.token);

                      if (response.isSuccess) {
                        this.editId = null;
                        this.isEdit = false
                        this.assignFeeEditId = null;
                        this.closeViewEditPopUp();
                        this.getFeesPlanList();
                        this.$toasted.success(response.message);
                      } else {
                        this.$toasted.error(response.message);
                         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
                      }
                      }
                    }else{
                       this.$toasted.error('Please assign atleast one fee type to all the terms');
                    }  
                  }else{
                    this.$toasted.error('Please Select a Fee Term');
                  }
               

            }else{
             this.$toasted.error('Please add at least one fee Type');
            }
          }else{
           this.$toasted.error('Please check term dates');
          }
      } else {
        this.$toasted.error(message);
      }
    },
    
    async submitForApproval(index){
     
      this.$bvModal.show("selectApprovePopup");
      const planDetails = this.feesPlanList[index];
      this.editId = planDetails._id;
      this.feePlan.planName = planDetails.planName;
      this.feePlan.academicYear = planDetails.academicYear;
      this.feePlan.feesStartDate = planDetails.feesStartDate.substring(0,10);
      this.feePlan.feesEndDate = planDetails.feesEndDate.substring(0,10);
      this.feePlan.feesDueDate = planDetails.feesDueDate.substring(0,10);
    	this.feePlan.classes = planDetails.classes || []
      this.feePlan.type = planDetails.type,
      this.feePlan.status = 'In Review'
    },
    async confirmSubmitForApproval(){

      this.feePlan.reportingTo = this.reportingTo
      
      let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(
            `/feesManagement/updateFeePlan/submitToApproval/${this.editId}`,
            this.feePlan,
            userData.token
          );

          if (response.isSuccess) {
            this.editId = null;
            this.isEdit = false
            this.$bvModal.hide("selectApprovePopup");
            this.reportingTo = null,
            this.reportingToEditId = null,
            this.getFeesPlanList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
    },
    async getInternalUserList() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

         const response = await ViService.viXGet("/teacher/getInternalUserList?viewType=list",userData.token);
			  	if (response.isSuccess) {
          const userList = secureUI.secureGet(response.data)
          
          this.internalUserList = userList.filter((val) => val.profile && val.profile.apiName == 'schoolAdmin')
         
        } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        } 
      }
    },
    async createFeePlan() {

      let isFormValid = false;

      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeplanValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && !this.minMonth && this.isFeePlanNameAvailable) {

         let isTermDateFormValid = false;

         let message = "Please fill all the required details";

          await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
            isTermDateFormValid = result;
          });

          if(isTermDateFormValid){

            if(this.assignedFeeType.oneTimeFrequency.length > 0){


                  const checkSelectTerm =  this.assignedFeeType.oneTimeFrequency.filter( x => x.term.length == 0 ) 
                  
                    if(checkSelectTerm.length == 0){

                      const termAmountCheck = this.termList.filter( x => x.amount == 0)
                      
                       if(termAmountCheck.length == 0){

                            let userData = secureUI.sessionGet("user");

                            if(!userData) {
                              this.$toasted.error("Please login and do the action");
                              this.$router.push('/login');
                            } else {
                              this.assignedFeeType.feesTerms =  this.termList;
                              this.assignedFeeType.feesPlanId = null;

                              if(this.showPenality){
                                  this.feePlan['showPenality'] = this.showPenality
                                  this.feePlan['penalty'] = this.penality
                              }else{
                                this.feePlan['showPenality'] = this.showPenality
                                this.feePlan['penalty'] = {}
                              } 

                              if(this.showDiscount){
                                 this.feePlan['showDiscount'] = this.showDiscount
                                 this.feePlan['discount'] = this.discount
                              }else{
                                this.feePlan['showDiscount'] = this.showDiscount
                                this.feePlan['discount'] = {}
                              }  

                              let obj = {
                                plan: this.feePlan,
                                assignFee: this.assignedFeeType
                              }

                              const response = await ViService.viXPost(
                                "/feesManagement/createFeePlan",
                                obj,
                                userData.token
                              );
                              
                              if (response.isSuccess) {
                                this.showPenality = false,
                                this.showDiscount = false,
                                this.closePoup();
                                this.getFeesPlanList();
                                this.getFeesPlanListAll();
                                this.$toasted.success(response.message);

                                let getCounts = this.$store.getters.getFeesManagementObjCount
                                getCounts.feesPlan = getCounts.feesPlan + 1

                                this.$store.dispatch('setFeesManagementObjCount', getCounts);

                              } else {
                                this.$toasted.error(response.message);
                                 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
                              }
                            }
                       }else{
                       this.$toasted.error('Please assign atleast one fee type to all the terms');
                       }
                    }else{
                       this.$toasted.error('Please Select a Fee Term');
                    }
               
            }else{
             this.$toasted.error('Please add at least one recurring fee Type');
            }
          }else{
           this.$toasted.error('Please check term dates');
          }
      } else {
        this.$toasted.error(message);
      }
    },
    async getClassList() {
      this.classList = []

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/contact/getClassList",userData.token);
       
        if (response.isSuccess) {

           const allClassListDetails = secureUI.secureGet(response.data);

          this.allClassList = allClassListDetails;
          this.classList = allClassListDetails
        this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
   components: {
      "v-select": Select,
      ViSpinner
    }
};
</script>