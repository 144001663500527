<template>
  <div>
    <div id="page-content">
      <div class="dispflex">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet p-3 m-2">
            <div class="tbllist mt-1">
              
                <div class="crtfeeplancont">
                  <div class="widjethdr dispflex" style="align-items: baseline">
                    <div style="width: auto">
                      <h1 style="margin: 0">Fee Types</h1>
                    </div>

                    <div class="mr-1">
                      <!-- <button class="btn gry_bg">
                        <i class="fas fa-search"></i>
                      </button> -->
                      <button
                        :disabled="assignedFeesTypeList.length > 0"
                        class="btn gry_bg tooltipt mpopup"
                        v-b-modal.crtfeesType
                      >
                        <i class="fas fa-list-ul"></i>
                        <span v-if="assignedFeesTypeList.length == 0" class="tooltiptext">Assigned Fee Types</span>
                        <span v-else class="tooltiptext">Fee Type already created</span>
                      </button>
                    </div>
                  </div>
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Fee Name</div>
                      <div class="cell">Billing Frequency</div>
                      <div class="cell">Amount($)</div>
                      
                      <div class="cell">Refundable</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow" v-for="(data, index) in assignedFeesTypeList" :key="index">
                      <div class="cell" data-title="Fee Name">
                        
                          {{ data.feesTypeName.name }}
                          <!-- <span class="tooltiptext"
                            >Total Term Fees : <b>{{ data.totalAmount }}</b></span
                          > -->
                       
                      </div>
                      <div class="cell" data-title="Billing Frequency">
                        {{ data.billingFrequency }}
                      </div>
                      <div class="cell" data-title="Amount">{{ data.totalAmount  }}</div>
                      <div class="cell" data-title="Refundable">{{ data.isRefundable ? 'Yes' : 'No' }}</div>
                      <div class="cell" data-title="Action">
                        <!-- <button class="btn round black tooltipt" @click.prevent="editFeeType(index)">
                         <i class="fa fa-eye" aria-hidden="true"></i><span class="tooltiptext">View</span>
                        </button> -->
                        <button class="btn round orange tooltipt" @click.prevent="editFeeType(index)">
                          <i class="fas fa-edit"></i
                          ><span class="tooltiptext">View / Edit</span>
                        </button>
                         <button :disabled="plan.status == 'Approved'" class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
                      </div>
                    </div>
                   
                  </div>
                  <div class="restable mt-1" style="border-top: 0">
                    <div class="resrow" style="background: #efefef">
                      <div class="cell">
                        Total Amount : <b>($) {{ totalFeesTerm }}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="widjetfooter text-right dk_iconsml mt-3">
                  <button
                    type="button"
                    id="prevBtn"
                    class="btn btnsml"
                     @click="$router.go(-1)"
                  >
                    Back
                  </button>
                 
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- show fees types -->

    <b-modal id="showFeestype" style="width: 100%"  no-close-on-backdrop no-close-on-esc>

    <div class="contact-form">
      <span class="close" @click.prevent="closeShowFeeType">&times;</span>
      <h2>Setup Fee Terms</h2>
      <br />
      <div style="height: 350px; overflow-y: auto">
        <div class="restable mt-3" style="border-top: 0">
          <div class="blutab">{{ previewFeesType.feesTypeName ? previewFeesType.feesTypeName.name : '' }}</div>
          <div class="resrow resheader">
            <div class="cell">Term Name</div>
            <div class="cell">Invoice Date</div>
            <div class="cell">Invoice Due Date</div>
            <div class="cell text-right">Amount ($)</div>
          </div>
          <div class="resrow" v-for="(type, index) in previewFeesType.feesTerms" :key="index" >
            <div class="cell" data-title="Term Name">Term {{ index + 1 }}</div>
            <div class="cell" data-title="Invoice Date">{{ type.feesStartDate | dateFormat }} </div>
            <div class="cell" data-title="Invoice Due Date">{{ type.feesEndDate | dateFormat }}</div>
            <div class="cell text-right" data-title="Amount ($)">
              {{ type.amount }}
            </div>
          </div>
          </div>
            <div class="restable mt-1" style="border-top: 0">
          <div class="resrow" style="background: #efefef">
            <div class="cell text-right" data-title="Term Name">
              Total Amount : <b>($) {{ previewFeesType.totalAmount ? previewFeesType.totalAmount : '' }} </b>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    </b-modal>    <!-- fee types-->
    <b-modal id="crtfeesType" style="width: 100%"  no-close-on-backdrop no-close-on-esc>
        <div class="contact-form">
          <h2>{{ editId ? 'Edit Assigned Fee Types' : 'Assigned Fee Types' }}</h2>
          <div>
              <form data-vv-scope="crtfeeTypeValidate" id="crtfeeType" accept-charset="utf-8">

              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                    
                      @change="checkFeetype"
                      v-model="assignedFeeType.feeTypeId"
                      :disabled="!isEdit && editId"
                      v-validate="'required'"
                      class="form-control form-input"
                      id="deptlistid"
                      name="feesType"
                    >
                    <option :value=null >  -- Select --  </option>
                    <option v-for="(item,index) in feesTypeList" :key="index" :value="item._id">
                      {{ item.name}}
                    </option>
                    </select>
                    <label
                      v-if="errors.first('crtfeeTypeValidate.feesType') || !isFeesTypeAvailable"
                      class="vi-error"
                      style="top:-1em"
                      >{{ errors.first('crtfeeTypeValidate.feesType') ? 'Fees Type is required ' : 'Fees Type already exist. please select a different one.' }}  </label
                    >

                    <label v-else for="acyearid">Fees Type <span class="required">*</span></label>
                    
                    
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                       @change="setTerms"
                       :disabled="!isEdit && editId"
                      name="Billing Frequency"
                      v-model="assignedFeeType.billingFrequency"
                      class="form-control form-input"
                      id="acyearid"
                      placeholder="Billing Frequency"
                      v-validate="'required'"
                    >
                    <option :value=null >  -- Select --  </option>
                    <option v-for="(item,index) in billingFrequencyList" :key="index" :value="item.value">
                      {{ item.label }}
                    </option>
                    </select>
                    <label
                      v-if="errors.first('crtfeeTypeValidate.Billing Frequency')"
                      class="vi-error"
                      style="top:-1em"
                      >Billing Frequency is required</label
                    >
                    <label v-else for="acyearid">Billing Frequency <span class="required">*</span></label>

                  
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                    <label
                      style="color: #6c757d; margin-bottom: 0.2rem"
                      class="mr-3"
                      >Refundable</label
                    >
                    <label class="switch" style="margin-bottom: 0rem">
                      <input :disabled="!isEdit && editId" type="checkbox" id="refundable"  v-model="assignedFeeType.isRefundable" />
                      <div class="slider"></div>
                    </label>
                  </div>
                </div>
                 <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                        type="text"
                        disabled
                        name="startDate"
                        :value="showDateMonthYearFormat(plan.feesStartDate)"
                        class="form-control form-input"
                        id="stdDob"
                        placeholder="Plan Start Date"
                    />
                   
                    <label  for="acyearid">Plan Start Date <span class="required">*</span></label>

                  
                  </span>
                </div>
                 <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                        type="text"
                        disabled
                        name="startDate"
                        :value="showDateMonthYearFormat(plan.feesEndDate)"
                        class="form-control form-input"
                        id="stdDob"
                        placeholder="Plan End Date"
                    />
                   
                    <label  for="acyearid">Plan End Date <span class="required">*</span></label>
                  
                  </span>
                </div>
                 
                <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
                  <span class="has-float-label">
                    <input
                        type="text"
                        disabled
                        name="startDate"
                        :value="noOfMonths"
                        class="form-control form-input"
                        id="stdDob"
                        placeholder="Plan Start Date"
                    />
                   
                    <label  for="acyearid">Duration</label>
                  
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
                  <span class="has-float-label" v-if="editId && showFeeTermEvery">
                    <input
                        type="text"
                        disabled
                        name="startDate"
                        v-model="assignedFeeType.feeTermEvery"
                        class="form-control form-input"
                        id="stdDob"
                        placeholder="Plan Start Date"
                    />
                   
                    <label  for="acyearid">Fee Term every <span class="required">*</span></label>
                  
                  </span>
                  <span class="has-float-label" v-else>
                    <select
                     v-model="assignedFeeType.feeTermEvery"
                     @change="setCustomTerms"
                      name="terms every"
                      class="form-control form-input"
                      id="every id"
                      v-validate="'required'"
                    >
                    <option :value=null >  -- Select --  </option>
                    <option v-for="(item,index) in termsEvery" :key="index" :value="item.value">
                      {{ item.label }}
                    </option>
                    </select>

                   <label
                      v-if="errors.first('crtfeeTypeValidate.terms every')"
                      class="vi-error"
                      style="top:-1em"
                      >Fee Term every is required</label
                    >

                    <label v-else for="acyearid">Fee Term every <span class="required">*</span></label>
                  
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
                  <span class="has-float-label">
                    
                    <select
                    :disabled="!isEdit && editId"
                     @change="clearInvoiceDate"
                     v-model="assignedFeeType.feeInvoiceDate"
                      name="Invoice dates"
                      class="form-control form-input"
                      id="Invoice dates id"
                      v-validate="'required'"
                    >
                    <option :value=null >  -- Select --  </option>
                    <option v-for="(item,index) in invoiceDates" :key="index" :value="item">
                      {{ item }}
                    </option>
                    </select>
                   <label
                      v-if="errors.first('crtfeeTypeValidate.Invoice dates')"
                      class="vi-error"
                      style="top:-1em"
                      >Invoice Date of every term month is required</label
                    >

                    <label v-else for="acyearid">Invoice Date of every term month <span class="required">*</span></label>
                  
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="assignedFeeType.billingFrequency == 'Custom'">
                  <span class="has-float-label">
                    
                    <select
                      :disabled="(!assignedFeeType.feeInvoiceDate && !editId ) ||  (!isEdit && editId )"
                      @change="setDueDates"
                      v-model="assignedFeeType.feeDueDate"
                      name="Due on"
                      class="form-control form-input"
                      id="dueonid"
                      v-validate="'required'"
                    >
                    <option :value=null >  -- Select --  </option>
                    <option v-for="(item,index) in invoiceDueDates" :key="index" :value="item.value">
                      {{ item.label }}
                    </option>
                    </select>
                    <label
                      v-if="errors.first('crtfeeTypeValidate.Due on')"
                      class="vi-error"
                      style="top:-1em"
                      >Due on( Days from Invoice Date ) is required</label
                    >
                    <label v-else for="acyearid">Due on( Days from Invoice Date ) <span class="required">*</span></label>
                  
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="termList.length > 0">
                  <span class="has-float-label">
                    <input
                        type="text"
                        disabled
                        name="startDate"
                        :value="termList.length"
                        class="form-control form-input"
                        id="stdDob"
                        placeholder="Plan Start Date"
                    />
                   
                    <label  for="acyearid">No of Terms <span class="required">*</span></label>
                  
                  </span>
                </div>
                <div class="col-md-12 mt-3" id="monthlyterms" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
                  <div id="prdwrapper" style="width: 100%">
                    <div id="prdcontainer">
                      <h1 class="termshd mb-3">Monthly Term Fees</h1>
                      <div id="content" class="mt-3">
                      
                        <div class="terms" :key="index" v-for="(item,index) in termList">
                          <h1 class="termstitle">{{ item.term }}</h1>
                          <div class="form-row">
                            <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                              <span class="has-float-label">
                                <input
                                  type="date"
                                  :disabled="!isEdit && editId"
                                  :min="item.minStartDate"
                                  :max="item.maxStartDate"
                                  @change="clearEndDate(item.feesStartDate,index)"
                                  onkeydown="return false" 
                                  :name="index + 'invoice'"
                                  v-model="item.feesStartDate"
                                  class="form-control form-input"
                                  id="t1invdateid"
                                  placeholder="Fee Start Date"
                                />
                                <label for="t1invdateid"
                                  >Fee Start Date
                                  </label
                                >
                              </span>
                            </div>
                            <div class="form-group input-group col-md-12 mt-3" v-if="assignedFeeType.billingFrequency != 'One-Time'">
                              <span class="has-float-label">
                                <input
                                  type="date"
                                  onkeydown="return false" 
                                  :name="index + 'due'"
                                  :disabled="!item.feesStartDate || (!isEdit && editId)"
                                  :min="item.feesStartDate"
                                  :max="item.maxStartDate"
                                  v-model="item.feesEndDate"
                                  class="form-control form-input"
                                  id="t1invddateid"
                                  v-validate="(item.feesStartDate) ? 'required':false"
                                  placeholder="Fee Due Date"
                                />
                                <label for="t1invddateid"
                                  >Fee Due Date
                                  </label
                                >
                              </span>
                            </div>
                            <div class="form-group input-group col-md-12 mt-2">
                              <span class="has-float-label">
                                <input
                                  type="number"
                                  :name="index + 'amount'"
                                  v-model="item.amount"
                                  :disabled="!isEdit && editId"
                                  class="form-control form-input"
                                  id="t1amtid"
                                  v-validate="(item.feesStartDate && item.feesEndDate) ? 'required':false"
                                />
                                 <!-- v-validate="agent.channelCode__c == 'gs' ? 'required' : false"
                                  v-validate="'required'" -->

                                <label for="t1amtid"
                                  >Amount </label
                                > 
                              </span>
                            </div>
                            <span class="required" v-if="errors.first(`crtfeeTypeValidate.${index + 'amount'}`) || errors.first(`crtfeeTypeValidate.${index + 'invoice'}`) || errors.first(`crtfeeTypeValidate.${index + 'due'}`)"> <span v-if="assignedFeeType.billingFrequency != 'One-Time'"> Fee Start Date ,Fee Due Date and </span>Amount is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                     <button id="slideLeft" @click.prevent="moveLeftSide">
                       
                      <i class="fas fa-angle-left"></i>
                    </button>
                     <button id="slideRight" @click.prevent="moveRightSide">
                     <i class="fas fa-angle-right"></i>
                    </button>
                  </div>
                </div>
                <div class="col-md-12 mt-3 pt-3" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
                <div class="text-right dk_iconsml" >
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    :disabled="!isEdit && editId"
                    @click.prevent="addFeeTypes"
                  >
                      <span>Add </span>
                  </button>
                </div>
               </div>
              </div>
              <div class="row" v-if="(assignedFeeType.billingFrequency != 'Custom' && termList.length > 0) || (assignedFeeType.billingFrequency == 'Custom' && assignedFeeType.feeInvoiceDate && assignedFeeType.feeDueDate)">
                    <div class="col-lg-12">
                       
                          <div class="admintbl mt-1 p-3">
                            <table border="1">
                              <thead>
                                <tr>
                                  <th scope="col" rowspan="2">
                                    Fees Type <span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Billing Frequency<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Refundable<span class="required">*</span>
                                  </th>
                                  <th scope="col" rowspan="2">
                                    Apply To<span class="required"
                                      >*</span
                                    >
                                  </th>
                                   <th scope="col" rowspan="2">
                                    Amount<span class="required"
                                      >*</span
                                    >
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, trindex) in assignedFeeType.oneTimeFrequency" :key="trindex">
                                  <td data-label="Fee Name">
                                    <select
                                    @change="checkAlreadySelected(item.feeTypeId,trindex)"
                                    v-model="item.feeTypeId"
                                    :disabled="!isEdit && editId"
                                    v-validate="'required'"
                                    class="form-control form-input"
                                    id="deptlistid"
                                     :name="trindex + 'feeType'" 
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(data,index) in removeCurrentFeeType" :key="index" :value="data._id">
                                    {{ data.name }}
                                  </option>
                                  </select>
                                  <span class="required" v-if="errors.first(`crtfeeTypeValidate.${trindex + 'feeType'}`)"> Fee type is required</span>
                                  </td>
                                  <td data-label="prefix">
                                    <input
                                    
                                      v-model="item.billingFrequency"
                                      disabled
                                      type="text"
                                      class="form-control form-input"
                                    />
                                  </td>
                                  <td data-label="format">
                                   <label class="switch" style="margin-bottom: 0rem">
                                      <input :disabled="!isEdit && editId" v-model="item.isRefundable" class="form-control form-input" type="checkbox" id="refundable"   />
                                      <div class="slider"></div>
                                    </label>
                                  </td>
                                   <td data-label="Fee Name">
                                    <!-- <select
                                    v-model="item.term"
                                    :disabled="!isEdit && editId"
                                    v-validate="'required'"
                                    class="form-control form-input"
                                    id="deptlistid"
                                     :name="trindex + 'applyTo'" 
                                  >
                                  <option :value=null >  -- Select --  </option>
                                  <option v-for="(item,index) in applyToList" :key="index" :value="item.value">
                                    {{ item.label }}
                                  </option>
                                  </select> -->
                                <a  href="javascript:void(0);"  @click.prevent="setApplyTerms(trindex)" >{{ editId ? 'View Terms' : 'Select Terms' }}</a>

                                  <!-- <v-select class="form-control form-input"
                                    label="label"
                                    multiple
                                    v-model="item.term"
                                    :disabled="!isEdit && editId"
                                    v-validate="'required'"
                                    id="deptlistid"
                                     :name="trindex + 'applyTo'" 
                                    :options="applyToList"
                                    :reduce="label => label.value"
                                  ></v-select> -->


                                    <span class="required" v-if="errors.first(`crtfeeTypeValidate.${trindex + 'applyTo'}`)"> Apply to is required</span>
                                  </td>
                                  <td data-label="Fee Name">
                                    <input
                                     v-validate="'required'"
                                      v-model="item.amount"
                                      :disabled="!isEdit && editId"
                                      type="number"
                                       :name="trindex + 'feeAmount'" 
                                      class="form-control form-input"
                                    />
                                     <span class="required" v-if="errors.first(`crtfeeTypeValidate.${trindex + 'feeAmount'}`)"> Amount is required</span>
                                  </td>
                                  <td>
                                    <button class="btn round orange tooltipt" :disabled="!isEdit && editId" >
                                     <i class="fas fa-minus-circle" @click.prevent="removeLineItem(trindex)" style="padding-top:4px;"></i><span class="tooltiptext">Remove</span>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        <!-- <div class="widjetfooter text-right">
                          <div class="dk_iconsml">
                            <button
                              type="button"
                              class="btn btnsml"
                              @click="$router.go(-1)"
                            >
                              <i class="fa fa-arrow-left"></i> <span>Back</span>
                            </button>
                           
                          </div>
                        </div> -->
                    </div>
              </div>

              <div
                class="col-md-12 mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5"
              >
                <div class="text-center dk_iconsml" v-if="!editId">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="createFeeType"
                    
                  >
                      <span>Save</span>
                  </button>
                  <button @click.prevent="closePoup" type="button" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
                </div>
                
                <div class="text-center dk_iconsml" v-else>
                  <button v-if="isEdit"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateFeeType"
                    
                  >
                      <span>Update</span>
                  </button>
                   <button v-if="!isEdit"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    :disabled="plan.planGenerated"
                    @click.prevent="allowEdit"
                    
                  >
                      <span>Edit</span>
                  </button>
                  <button @click.prevent="closeEditPoup" type="button" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
      </div>
    </b-modal>
     <b-modal id="deleteFeeTypePopup">
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
       Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
     <b-modal id="showApplyTermsPopUp" no-close-on-backdrop no-close-on-esc >
        <div class="contact-form">
          <h2>Select Terms Apply to  </h2>
        
          <div>
               <div class="row mt-4">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjetcontent">
                 <div class="form-row">
                            <div class="form-group input-group col-md-12 mt-3" >
                              <span class="has-float-label">
                                 <v-select class="form-control form-input"
                                    label="label"
                                    multiple
                                    :disabled="!isEdit && editId"
                                    v-model="multiselectForTerm"
                                    id="deptlistid"
                                    :options="applyToList"
                                    :reduce="label => label.value"
                                  ></v-select>

                                <label for="t1invdateid"
                                  >Apply To
                                  <span class="required">*</span></label
                                >
                              </span>
                             </div>
                              </div>
                </div>
               
              </div>
            </div>
               </div>
             
              <div
                class="pt-3 text-right dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    :disabled="!isEdit && editId"
                    @click.prevent="confirmSelectedTerms"
                    
                  >
                      <span>Confirm</span>
                  </button>
                  <button  @click.prevent="closeTermsPoup" type="button" class="btn btncl clsmdl" id="clbtn">
                       <span>Cancel</span>
                  </button>
              </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "vi-fees-management",
  data() {
    return {
    plan: {},
    deleteId: null,
    editId:  null,
    showFeeTermEvery: true,
    assignedTermsIndex: null,
    multiselectForTerm: [],
		assignedFeeType: {

      feeTypeId: null,
      billingFrequency: '',
      isRefundable : false,
      feesPlanId : null,
      feeTermEvery: '',
      feeInvoiceDate: '',
      feeDueDate: '',
      feesTerms : [],
      oneTimeFrequency: []
      
		},
    previewFeesType: {},
    feesTypeList: [],
    noOfMonths: 0,
    prevFeetypeId: null,
    isFeesTypeAvailable: true,
    isEdit: false,
    assignedFeesTypeList : [],
    billingFrequencyList: [
      {
        label: 'Monthly',
        value: 'Monthly',
        days: 30
      },
      {
        label: 'Custom',
        value: 'Custom',
        days: 30
      }
    ],
    termList:[],
    termsEvery: [],
    invoiceDates: [
       '1',
       '2',
       '3',
       '4',
       '5',
       '6',
       '7',
       '8',
       '9',
       '10',
       '11',
       '12',
       '13',
       '14',
       '15',
       '16',
       '17',
       '18',
       '19',
       '20',
       '21',
       '22',
       '23',
       '24',
       '25',
       '26',
       '27',
       '28',
       '29',
       '30',
       '31'
    ],
    invoiceDueDates: [{
      label: '1 day',
      value: '1'
    },
    {
      label: '2 days',
      value: '2'
    },
    {
      label: '3 days',
      value: '3'
    },
    {
      label: '4 days',
      value: '4'
    },
    {
      label: '5 days',
      value: '5'
    },
    {
      label: '6 days',
      value: '6'
    },
    {
      label: '7 days',
      value: '7'
    },
    {
      label: '8 days',
      value: '8'
    },
    {
      label: '9 days',
      value: '9'
    },
    {
      label: '10 days',
      value: '10'
    },
    {
      label: '11 days',
      value: '11'
    },
    {
      label: '12 days',
      value: '12'
    },
    {
      label: '13 days',
      value: '13'
    },
    {
      label: '14 days',
      value: '14'
    },
    {
      label: '15 days',
      value: '15'
    },
    {
      label: '16 days',
      value: '16'
    },
    {
      label: '17 days',
      value: '17'
    },
    {
      label: '18 days',
      value: '18'
    },
    {
      label: '19 days',
      value: '19'
    },
    {
      label: '20 days',
      value: '20'
    },
    {
      label: '21 days',
      value: '21'
    },
    {
      label: '22 days',
      value: '22'
    },
    {
      label: '23 days',
      value: '23'
    },
    {
      label: '24 days',
      value: '24'
    },
    {
      label: '25 days',
      value: '25'
    },
    {
      label: '26 days',
      value: '26'
    },
    {
      label: '27 days',
      value: '27'
    },
    {
      label: '28 days',
      value: '28'
    },
    {
      label: '29 days',
      value: '29'
    },
    {
      label: '30 days',
      value: '30'
    },
    {
      label: '31 days',
      value: '31'
    }
    ]
    };
  },
 created() {
    this.getFeesTypeList();
    this.getAssignedFeesTypeList();
 },
 computed: {

      totalFeesTerm() {
        if(this.assignedFeesTypeList.length > 0){
          return this.assignedFeesTypeList.map(o => o.totalAmount).reduce((a, c) => { return a + c });
        }else{
          return 0
        }
       
      },
       removeCurrentFeeType() {
        if(this.assignedFeeType.feeTypeId){
          return this.feesTypeList.filter( x => x._id != this.assignedFeeType.feeTypeId);
        }else{
          return []
        }
       
      },
      applyToList() {
        const checkTermList = []

        for(let x of this.termList){
           if(x.feesStartDate && x.feesStartDate && x.amount){
             checkTermList.push(x)
           }
        }
        
        return checkTermList.map(x => {
        return {
          label: x.term,
          value: x.term
        };
       });

      }
     
 },
 methods: {
    
  redirectRoute(vipath) {
    if (this.$route.path !== vipath) this.$router.push({ path: vipath });
  },
  allowEdit(){
   this.isEdit = true
  },
  setApplyTerms(index){
    this.assignedTermsIndex = null,
    this.multiselectForTerm = [],
    this.assignedTermsIndex = index,
    this.multiselectForTerm =  this.assignedFeeType.oneTimeFrequency[index].term,
    this.$bvModal.show("showApplyTermsPopUp");
  },
  confirmSelectedTerms(){
    if(this.multiselectForTerm.length > 0){
      this.assignedFeeType.oneTimeFrequency[this.assignedTermsIndex].term = this.multiselectForTerm
      this.$bvModal.hide("showApplyTermsPopUp");
      this.assignedTermsIndex = null,
      this.multiselectForTerm = []
    }else{
      this.$toasted.error(`Please select. Minimum one term`);
    }
    
  },
  closeTermsPoup(){
  this.assignedTermsIndex = null,
  this.multiselectForTerm = [],
  this.$bvModal.hide("showApplyTermsPopUp");
  },
  checkAlreadySelected(value,index){
    const getFeeTypes = this.assignedFeeType.oneTimeFrequency.filter( x => (x.feeTypeId == value.toString()) && x.feeTypeId != 'null' && x.feeTypeId != '' &&  x.feeTypeId != undefined)
    if(getFeeTypes.length > 1){
      this.assignedFeeType.oneTimeFrequency[index].feeTypeId = null
      this.$toasted.error(`Already selected. Please use differnet one`);
    }
   
  },
  addFeeTypes(){
   this.assignedFeeType.oneTimeFrequency.push({
        feeTypeId: null,
        billingFrequency: 'One-Time',
        isRefundable : false,
        term : '',
        amount: null
    })
  },
  removeLineItem(index){
		 this.assignedFeeType.oneTimeFrequency.splice(index,1);
	},
  moveRightSide(){
    document.getElementById('content').scrollLeft += 222;
  },
  moveLeftSide(){
   document.getElementById('content').scrollLeft -= 222;
  },
  clearEndDate(value,index){
     if (value == '' || value == null || value == undefined) {
        this.termList.forEach((data,i) => {
          if (i == index) {
            data.feesEndDate = null;
          } 
        });
      }
   
  },

	closePoup() {
      this.$bvModal.hide("crtfeesType");
      this.assignedFeeType.feeTypeId = null,
      this.assignedFeeType.billingFrequency = '',
      this.assignedFeeType.feeTermEvery = '',
      this.assignedFeeType.feeInvoiceDate = '',
      this.assignedFeeType.feeDueDate = '',
      this.assignedFeeType.isRefundable = false,
      this.assignedFeeType.feesPlanId = null,
      this.assignedFeeType.feesTerms = [],
      this.termList = [],
      this.assignedFeeType.oneTimeFrequency = []
      this.isFeesTypeAvailable = true
      

   },
   
   closeEditPoup() {
      this.$bvModal.hide("crtfeesType");
      this.assignedFeeType.feeTypeId = null,
      this.assignedFeeType.billingFrequency = '',
      this.assignedFeeType.isRefundable = false,
      this.assignedFeeType.feesPlanId = null,
      this.assignedFeeType.feeTermEvery = '',
      this.assignedFeeType.feeInvoiceDate = '',
      this.assignedFeeType.feeDueDate = '',
      this.assignedFeeType.feesTerms = [],
      this.termList = [],
      this.editId = null,
      this.assignedFeeType.oneTimeFrequency = []
      this.isFeesTypeAvailable = true,
      this.isEdit = false
      

   },
    showFeeTypes(index){
        let showFeesType = this.assignedFeesTypeList[index];
        this.previewFeesType = showFeesType;
        this.$bvModal.show("showFeestype");
       
    },
    showDateMonthYearFormat(value){
        if (value) {
            let newDate = new Date(value);
            let getdate = newDate.getDate()
            let getMonth = newDate.getMonth() + 1

            if(getdate < 10){
               getdate = '0' + getdate;
            } 
            if(getMonth < 10){
               getMonth = '0' + getMonth;
            }

            return getdate + "-" + getMonth + "-" + newDate.getFullYear();
        } else {
                return '';
        }
    },
    closeShowFeeType(){
      this.$bvModal.hide("showFeestype");
      this.previewFeesType = {},
      this.isFeesTypeAvailable = true
       
    },
     editFeeType(index){
      this.assignedFeeType.oneTimeFrequency = []
      const assignedFeeTypeDetail = this.assignedFeesTypeList[index]
      this.editId = assignedFeeTypeDetail._id,
      this.assignedFeeType.feesPlanId = assignedFeeTypeDetail.feesPlanId,
      this.assignedFeeType.feeTypeId = assignedFeeTypeDetail.feesTypeName._id,
      this.assignedFeeType.billingFrequency = assignedFeeTypeDetail.billingFrequency,
      this.assignedFeeType.isRefundable = assignedFeeTypeDetail.isRefundable,
      this.assignedFeeType.oneTimeFrequency = assignedFeeTypeDetail.oneTimeFrequency,
      this.assignedFeeType.feeTermEvery = assignedFeeTypeDetail.feeTermEvery,
      this.assignedFeeType.feeInvoiceDate = assignedFeeTypeDetail.feeInvoiceDate,
      this.assignedFeeType.feeDueDate = assignedFeeTypeDetail.feeDueDate,

      this.prevFeetypeId = assignedFeeTypeDetail.feesTypeName._id

      for (let x of assignedFeeTypeDetail.feesTerms){

       this.termList.push({

          amount: x.amount || null,
          feesEndDate: x.feesEndDate ? x.feesEndDate.substring(0,10) : null,
          feesStartDate: x.feesStartDate ? x.feesStartDate.substring(0,10) : null,
          minStartDate: x.minStartDate ? x.minStartDate.substring(0,10) : null,
          maxStartDate: x.maxStartDate ? x.maxStartDate.substring(0,10) : null,
          term: x.term

         })
      }
      this.$bvModal.show("crtfeesType");
    },
     showDeleteModal(index){

      const assignedFeeTypeDetail = this.assignedFeesTypeList[index]
      this.deleteId = assignedFeeTypeDetail._id,
     
      this.$bvModal.show("deleteFeeTypePopup");

    },
    closeDeletePopUp(){
      this.$bvModal.hide("deleteFeeTypePopup");
      this.deleteId = null;
      this.assignedFeeType.feeTermEvery = '',
      this.assignedFeeType.feeInvoiceDate = '',
      this.assignedFeeType.feeDueDate = ''
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viDelete(`/feesManagement/deleteAssignedFeesType/${this.deleteId}`,userData.token);

        if (response.isSuccess) {
          this.closeDeletePopUp();
          this.getAssignedFeesTypeList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getFeesTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/feesManagement/getFeesTypeList",
          userData.token
        );
        if (response.isSuccess) {
          this.feesTypeList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async checkFeetype(){
      if(this.assignedFeeType.feeTypeId){
       this.isFeesTypeAvailable = true
       let allowDupUser = true;

        if (this.prevFeetypeId) {
          if ( this.assignedFeeType.feeTypeId == this.prevFeetypeId) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {

     let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        

        const response = await ViService.viXGet(`/feesManagement/checkFeeType?planId=${this.$route.params.id}&feeTypeId=${this.assignedFeeType.feeTypeId}`,userData.token);

        if (response.isSuccess) {
          this.isFeesTypeAvailable = response.isApiNameAvailable
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
   }
    },
    async getAssignedFeesTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/feesManagement/getAssignedFeesTypeList?planId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const result = secureUI.secureGet(response.data);

          this.plan = result.planDetails
          
          this.plan.feesStartDate = this.plan.feesStartDate.substring(0,10)
          this.plan.feesEndDate = this.plan.feesEndDate.substring(0,10)

          this.assignedFeesTypeList = result.feeList;
          this.calculateBillingFrequency();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    clearInvoiceDate(){
      if(!this.assignedFeeType.feeInvoiceDate){
       this.assignedFeeType.feeDueDate = ''
      }
    },
    setDueDates(){
    
      for(let x of this.termList){
        let someDate = new Date(x.feesStartDate);
        let numberOfDaysToAdd = Number(this.assignedFeeType.feeInvoiceDate) - 1;
        x.feesStartDate = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));

        x.feesStartDate = this.checkAndSetCurrentDate(x.feesStartDate)
      }

      for(let x of this.termList){
        let someDate = new Date(x.feesStartDate);
        let numberOfDaysToAdd = Number(this.assignedFeeType.feeDueDate);
        x.feesEndDate = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
        x.feesEndDate = this.checkAndSetCurrentDate(x.feesEndDate)
      }
    },
    checkAndSetCurrentDate(startCurrentDate){
            let getCurrentYear = startCurrentDate.getFullYear()
            let getCurrentMonth = startCurrentDate.getMonth() + 1
            let getCurrentDate = startCurrentDate.getDate()
            
            if (getCurrentMonth < 10) {
              getCurrentMonth = '0' + getCurrentMonth;
            }

            if (getCurrentDate < 10) {
              getCurrentDate = '0' + getCurrentDate;
            }

            return getCurrentYear + "-" + getCurrentMonth + "-" + getCurrentDate;
    },
    setCustomTerms(){
     if(this.assignedFeeType.feeTermEvery){

        let startCurrentDate = null

        let planDate = new Date(this.plan.feesStartDate);

        let currentDate = new Date()

        if(currentDate > planDate){
             startCurrentDate = currentDate
        }else{
           startCurrentDate = planDate;
        }

        let endDate = new Date(this.plan.feesEndDate);
        let finalcurrentDate = null
        let months = 0;

        months = (endDate.getFullYear() - startCurrentDate.getFullYear()) * 12;
        months -= startCurrentDate.getMonth();
        months += endDate.getMonth() + 1;
        

        if(months == 13){
            months = months - 1
        }


        this.noOfMonths = months

        const fixedOneDecimal = (this.noOfMonths/this.assignedFeeType.feeTermEvery).toFixed(1)

        const customTermsCount = Math.round(fixedOneDecimal)

        if(customTermsCount){

        this.termList = [];
        
        this.assignedFeeType.oneTimeFrequency = []

        let count =  customTermsCount;

     
        let startDate = new Date();

        let nextMonth = this.assignedFeeType.feeTermEvery

        for(let i = 0; i < count; i++) {
            
           if(this.termList.length != 0){

            startDate = startCurrentDate

            startDate.setMonth(startDate.getMonth() + nextMonth);
            let newMonth = startDate.getMonth()+1
            
            if (newMonth < 10) {
              newMonth = '0' + newMonth;
            }

            startDate =  startDate.getFullYear() + "-" + newMonth + "-" + '01';
           }else{

            let getCurrentYear = startCurrentDate.getFullYear()
            let getCurrentMonth = startCurrentDate.getMonth()+1
            let getCurrentDate = startCurrentDate.getDate()
            
            if (getCurrentMonth < 10) {
              getCurrentMonth = '0' + getCurrentMonth;
            }

            if (getCurrentDate < 10) {
              getCurrentDate = '0' + getCurrentDate;
            }


            finalcurrentDate =  getCurrentYear + "-" + getCurrentMonth + "-" + getCurrentDate;
           }

            this.termList.push({
              term: `Term ${i + 1 }`,
              feesStartDate: this.termList.length == 0 ? finalcurrentDate : startDate, 
              minStartDate: finalcurrentDate,
              maxStartDate: this.plan.feesEndDate,
              feesEndDate: null,
              amount: null
          })
         
        }
        
        }
     }else{
       this.termList = [],
       this.assignedFeeType.feeInvoiceDate = null,
       this.assignedFeeType.feeDueDate = ''
     }
    },
    calculateBillingFrequency(){

          this.billingFrequencyList = [{
            label: 'Monthly',
            value: 'Monthly',
            days: 30
          },
          {
            label: 'Custom',
            value: 'Custom',
            days: 30
          }]

          let startDate = new Date(this.plan.feesStartDate);
          let endDate = new Date(this.plan.feesEndDate);

          let months = 0;

          months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
          months -= startDate.getMonth();
          months += endDate.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
         
           if(months % 3 == 0) {
             this.billingFrequencyList.push( 
               {
                  label: 'Quarterly',
                  value: 'Quarterly',
                  days: 30
               })

          } 

          if (months % 6 == 0 ) {
          this.billingFrequencyList.push( 
              {
                label: 'Half Yearly',
                value: 'Half Yearly',
                days: 30
              })
          } 
          
    },
    setTerms(){

      if(this.assignedFeeType.billingFrequency){
        this.showFeeTermEvery = false
        this.termList = [];
        this.assignedFeeType.oneTimeFrequency = []

        if(this.assignedFeeType.billingFrequency != 'Custom'){
        
        let count = 0;
        if(this.assignedFeeType.billingFrequency == 'Monthly'){
            count = this.noOfMonths
        }else if(this.assignedFeeType.billingFrequency == 'Quarterly'){
            count = (this.noOfMonths/3)
        }else if(this.assignedFeeType.billingFrequency == 'Half Yearly' ){
            count = (this.noOfMonths/6)
        }
       
        let startDate = new Date(this.plan.feesStartDate);

        let currentDate = new Date()

        let finalStartDate = null

        if(currentDate > startDate){
             
               let addOneCurrentMonth = currentDate.getMonth() + 1

               if(addOneCurrentMonth < 10) {
                  addOneCurrentMonth = '0' + addOneCurrentMonth;
               }
               
               let getCurrentDate = currentDate.getDate()

               if(getCurrentDate < 10) {
                  getCurrentDate = '0' + getCurrentDate;
               }

              finalStartDate =  currentDate.getFullYear() + "-" + addOneCurrentMonth + "-" + getCurrentDate;
        }else{
         finalStartDate = this.plan.feesStartDate;
        }

        let nextMonth = 0

        if(count == 12){
          nextMonth = 1
        }

        if(count == 4){
          nextMonth = 3
        }

         if(count == 6 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 7 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 8 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 9 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 10 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }
         if(count == 11 && this.assignedFeeType.billingFrequency == 'Monthly'){
          nextMonth = 1
        }

        if(count == 2){
          nextMonth = 6
        }

        if(count == 2 && this.assignedFeeType.billingFrequency == 'Quarterly'){
          nextMonth = 3
        }

        if(count == 3 && this.assignedFeeType.billingFrequency == 'Quarterly'){
          nextMonth = 3
        }
       
        for(let i = 0; i < count; i++) {

            this.termList.push({
              term: `Term ${i + 1 }`,
              feesStartDate: null, 
              minStartDate: finalStartDate,
              maxStartDate: this.plan.feesEndDate,
              feesEndDate: null,
              amount: null
          })
         
        }
        }else{

        let startDate = new Date(this.plan.feesStartDate);

        let currentDate = new Date()

        let finalStartDate = null

        if(currentDate > startDate){
          let startDate = new Date();
          let endDate = new Date(this.plan.feesEndDate);

          let months = 0;

          months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
          months -= startDate.getMonth();
          months += endDate.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
             
        }else{
          let endDate = new Date(this.plan.feesEndDate);

          let months = 0;

          months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
          months -= startDate.getMonth();
          months += endDate.getMonth() + 1;
         

          if(months == 13){
              months = months - 1
          }

          this.noOfMonths = months
        }

          this.termsEvery = []
          for(let i = 0; i < this.noOfMonths; i++) {

            this.termsEvery.push({
              label: i + 1 + " " + 'Months',
              value: i + 1
            })

          }
        }
      }
      
    },
	  async createFeeType() {

      this.assignedFeeType.feesTerms =  this.termList;
      this.assignedFeeType.feesPlanId = this.$route.params.id;

      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
        isFormValid = result;
      });

		if(isFormValid) {
      if(this.isFeesTypeAvailable){

			let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {

          const response = await ViService.viXPost("/feesManagement/assignedFeesType",this.assignedFeeType,userData.token );

          if (response.isSuccess) {
            this.closePoup();
            this.getAssignedFeesTypeList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        
        }
      }
      }else{
        this.$toasted.error(message);
      }
    },
    async updateFeeType() {

      this.assignedFeeType.feesTerms =  this.termList;

      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll('crtfeeTypeValidate').then(result => {
        isFormValid = result;
      });

		if(isFormValid) {
      if(this.isFeesTypeAvailable){
			let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPut(`/feesManagement/updateAssignedFeesType/${this.editId}`,this.assignedFeeType,userData.token );

          if (response.isSuccess) {
            this.closeEditPoup();
            this.getAssignedFeesTypeList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        
        }
       }
      }else{
        this.$toasted.error(message);
      }
    }
 },
 components: {
      "v-select": Select
    }
};
</script>